const tutorTab = require('./tutorTab');
const personalInformationTab = require('./personalInformationTab');
const taxDataTab = require('./taxDataTab');
const contactInformationTab = require('./contactInformationTab');

module.exports = {
    order: 3,
    footer_text: '',
    position: 'middle',
    disableNext: 'juniorAccount_disableSecondStepNextButton',
    components: [
        {
            id_component: 'radio-button',
            id: 'juniorAccount_cardOwner',
            label: 'contractsView-juniorAccountCardOwnerLabel',
            type: 'form',
            target: 'junior_form_card_owners',
            onChangeFn: 'juniorAccount_onChangeCardOwner',
            options: [],
            source: 'juniorAccount_getCardOwners',
            default_value: '0',
            msg_error: '',
            value: '0',
            buildCondition: 'juniorAccount_hasCardOwners',
        },
        {
            id_component: 'ok-button',
            id: 'new-beneficiary-button',
            label: 'contractsView-openyoungFormNewOpenYoung',
            className: 'contract-view__ok-button--junior-account-new-beneficiary',
            modifier: 'ghost',
            onClickFn: 'juniorAccount_onClickNewBeneficiary',
            type: 'form',
            target: 'junior_form_card_owners',
            icon: 'icon-agregar',
            buildCondition: 'juniorAccount_hasCardOwners',
        },
        {
            id_component: 'loading-section',
            id: 'loading-section',
            visible: {
                target: 'juniorAccount_cardOwner',
                value: ['loading']
            }
        },
        tutorTab,
        personalInformationTab,
        contactInformationTab,
        taxDataTab,
        {
            id_component: 'separador',
        },
        {
            id_component: 'checkbox',
            labelElements: [
                {
                    type: 'text',
                    textKey: 'contractsView-juniorAccountStep2CheckboxTutorText'
                },
                {
                    type: 'link',
                    modifier: 'emphasize',
                    urlKey: 'contractsView-juniorAccountStep2CheckboxTutorUrl',
                    textKey: 'contractsView-juniorAccountStep2CheckboxTutorLink'
                }
            ],
            id: 'checkbox_agreement',
            target: 'checkbox_agreement',
            type: 'single',
            onChangeFn: 'juniorAccount_onChangeCheckBox',
            errorMessage: 'contractsView-serviceContractErrorMessage',
            required: true,
            buildCondition: 'juniorAccount_showBeneficiaryDataForms',
        },
        {
            id_component: 'information-modal',
            id: 'different_ship_country',
            title: 'contractsView-validationCountryModalTitle',
            content: 'contractsView-validationCountryModalBody',
            action: 'juniorAccount_modalInfoAction',
            buttonText: 'contractsView-validationCountryModalButtonText',
            openModal: false
        }
    ]
};
