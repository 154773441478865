// @ vendors
const React = require('react');
//@commons
const { FormattedText } = require('core/i18n').i18n;

//@helpers
const formValidationHelper = require('../utilities/formValidationHelper');
const { formatAmount } = require('utilities/currencyHelper');
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');
const { getDepositsPrintedDocument } = require('../utilities/contactCenter/depositsPrintedDocumentHelper');
const { getMainDepositCategory, getDepositProduct } = require('utilities/taggingDeposits');

//@constants
const {
    BUTTON_CLICK,
    CANCEL_ACTION_EVENT,
    CONTRACT_BUTTON,
    LINK_CLICK_ACTION_EVENT,
    NEXT_ACTION_EVENT,
    PREVIOUS_ACTION_EVENT
} = require('constants/tagging');
const { 
    EURO_SYMBOL,
    API_CENTER,
    API_ENTERPRISE_NUMBER,
    DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
    DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
    DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
    DEPOSIT_HIRE_CONFIRMATION_MANAGER
} = require('constants/index');

function deposittransfer_shouldInputSubLabelBuild() {
    return false;
}

function selectedAccountBalance(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const ctaSeleccionada = findElementbyId(cardDetails, 'cta_seleccionada');
    const selectedAccountBalance =
        contractWizard.getIn([
            's_accounts',
            'value',
            'byId',
            ctaSeleccionada,
            'balances',
            'availableBalance',
            'amount'
        ]) || findElementbyId(cardDetails, 'balance_account');
    return selectedAccountBalance;
}

function pollingNextButtonDisabled(contractWizard) {
    return !contractWizard.get('conditionDocumentConfirmation') && !__CONTACT_CENTER__ || (__CONTACT_CENTER__ && !contractWizard.getIn(['confirmationCheckboxes', 'areValid']));
}

function nextButtonDisabled(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = cardDetails && findElementbyId(cardDetails, 'monto_traspasar');
    return !!(cardDetails && !findElementbyId(cardDetails, 'cta_seleccionada', 'description') ||
     contractWizard.get('intervenersIsFetching') || contractWizard.get('dataIsFetching') || !depositAmount ||
     contractWizard.getIn(['depositHireData', 'error']) || depositAmount > selectedAccountBalance(contractWizard));
}

function getCustomPrintDocument(contractWizard, immProfile, immAccounts) {
    return getDepositsPrintedDocument(contractWizard, immProfile, immAccounts);
}

function getDepositElement(contractWizard, element) {
    const deposit = contractWizard.getIn(['mainDeposits', 'data']);
    return deposit ? deposit.get(element) : '';
}

function getEventCategory(contractWizard, step = false, page = undefined) {
    const visibleStep = contractWizard.get('visibleStep');
    const standard =  getDepositElement(contractWizard, 'standard');
    const subType = getDepositElement(contractWizard, 'subtype');
    const type = getDepositElement(contractWizard, 'type');
    return getMainDepositCategory(type, subType, standard, step ? visibleStep : undefined, page);
}


function customTagging(contractWizard) {
    const eventCategory = getEventCategory(contractWizard, true);
    const standard =  getDepositElement(contractWizard, 'standard');
    const subType = getDepositElement(contractWizard, 'subtype');
    const type = getDepositElement(contractWizard, 'type');
    return {
        events: {
            cancel: { eventCategory: eventCategory, eventName: LINK_CLICK_ACTION_EVENT, eventTag: CANCEL_ACTION_EVENT },
            finish: { eventCategory: eventCategory, eventName: BUTTON_CLICK, eventTag: CONTRACT_BUTTON },
            next: { eventCategory: eventCategory, eventName: BUTTON_CLICK, eventTag: NEXT_ACTION_EVENT },
            previous: { eventCategory: eventCategory, eventName: BUTTON_CLICK, eventTag: PREVIOUS_ACTION_EVENT },
        },
        steps: {
            nombrePagina: 'depositos/transferencia',
            detalleProceso: getEventCategory(contractWizard, false, 'transferencia'),
            nombreProducto: getDepositProduct(type, subType, standard),
            productName: getDepositProduct(type, subType, standard),
        },
        eventCategory: eventCategory,
    }
}

function customCategoryEvent(contractWizard) {
    return getEventCategory(contractWizard, true);
}

function inputCategoryEvent(contractWizard) {
    return getEventCategory(contractWizard, true);
}

function validateStep1(card_details) {
    return {
        card_details: card_details,
        isValidStep: true
    };
}

function validateStep2(cardDetails, state) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    let isValidAccount = selectedAccount !== '' && selectedAccount !== null;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    if (isValidAccount) {
        let interveners = state.get('interveners');
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners
                .map(intervener => intervener.get('type') !== '')
                .toArray();
        }

        let validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'cta_seleccionada',
            !isValidAccount
        );
    }
    const isInputAmountNotZero = findElementbyId(cardDetails, 'monto_traspasar') !== 0;

    return {
        card_details: cardDetails,
        isValidStep: isValidAccount && isInputAmountNotZero
    };
}


function validateStep3(card_details, conditionDocumentConfirmation) {
    let validation;
    if (!__CONTACT_CENTER__) {
        validation = {
            card_details: card_details,
            isValidStep: conditionDocumentConfirmation,
        };
    } else {
        validation = {
            card_details: card_details,
            isValidStep: true,
        };
    }
    return validation;
}

function validateStep4(cardDetails, immConfirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = immConfirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details: cardDetails,
        isValidStep: immConfirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: immConfirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateStep(step, state, isValid) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails, state.get('depositHireData'));

        case 2:
            return validateStep2(immCardDetails, state, isValid);

        case 3:
            return validateStep3(immCardDetails, state.get('conditionDocumentConfirmation'));
            
        case 4:
            return validateStep4(immCardDetails, immConfirmationCheckboxes);
            
    }
}

function deposittransfer_loadAmountInputAmounts(contractWizard, component) {
    if (!contractWizard.get('dataIsFetching')) {
        const depositHireData = contractWizard.get('depositHireData');
        component.importMin = depositHireData.get('importeMin');
        component.importMax = depositHireData.get('importeMax');
        component.defaultValue = depositHireData.get('importeMin');
        component.value = component.value || depositHireData.get('importeMin');
        const chosenDepositMax = contractWizard.getIn(['deposit_product', 'max']);
        const chosenDepositMin = contractWizard.getIn(['deposit_product', 'min']);
        component.infoMessage = chosenDepositMax
            ? <FormattedText value={component.infoMessageComplete} injectedStrings={[`${formatAmount(chosenDepositMin)} ${EURO_SYMBOL}`, `${formatAmount(chosenDepositMax)} ${EURO_SYMBOL}`]} />
            : <FormattedText value={component.infoMessageMinimum} injectedStrings={[`${formatAmount(chosenDepositMin)} ${EURO_SYMBOL}`]} />;
    }
    return component;
}

function dropdwonAccountShouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'installment', 'value')
    );
}

function shouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'cta_seleccionada', 'description') &&
        !contractWizard.get('intervenersIsFetching') &&
        !contractWizard.get('dataIsFetching') &&
        !contractWizard.getIn(['depositHireData', 'error'])
    );
}

function signParams(contractWizard, immAccounts) {
    const immCardDetails = contractWizard.get('card_details');
    const selectedAccountId = immCardDetails
        ? findElementbyId(immCardDetails, 'cta_seleccionada')
        : null;
    let selectedAccount;

    if (immAccounts && selectedAccountId) {
        const immAccount = immAccounts.getIn(['byId', selectedAccountId]);
        selectedAccount =
            API_ENTERPRISE_NUMBER +
            API_CENTER +
            immAccount.get('product') +
            immAccount.get('contractNumber');
    }

    return {
        originAccount: selectedAccount
    };
}

function onChangeAmount(amount) {
    let ret = [];
    ret.push({
        target: 'monto_traspasar',
        value: amount
    });
    return ret;
}

function setSuccessStepPrefix() {
    let successStepPrefix = {};

    return successStepPrefix;
}


function setSuccessStepAdditionalInfo() {
    return null;
}

function hireParamsFn(contractWizard, immAccount) {
    const cardDetails = contractWizard.get('card_details');
    const depositProduct = contractWizard.get('deposit_product');
    const depositHireData = contractWizard.get('depositHireData');
    const importe = findElementbyId(cardDetails, 'monto_traspasar');
    const interveners = contractWizard.get('interveners');
    const chosenDeposit = contractWizard.getIn(['mainDeposits', 'data']).toJS();
    const intervenersData = interveners.map((intervener, index) => ({
        desIntervencion: intervener.get('type'),
        formaIntervencion: intervener.get('interventionWay'),
        nombre: intervener.get('name'),
        numeroPersona: intervener.getIn(['identification', 'code']),
        ordenIntervencion: index,
        tipoIntervencion: intervener.get('typeNumber'),
        tipoPersona: intervener.getIn(['identification', 'type'])
    }));

    return {
        aa1: depositHireData.get('aa1'),
        aa2: depositHireData.get('aa2'),
        aa3: depositHireData.get('aa3'),
        adelta: depositHireData.get('adelta'),
        campana: DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
        canalComercializacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        canalOperacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        codPeridoLiq: depositHireData.get('codPeridoLiq'),
        cotPeriodo: depositHireData.get('cotPeriodo'),
        cuentaSeleccionada: {
            numerodecontrato: immAccount.get('contractNumber'),
            producto: immAccount.get('product')
        },
        datos: {
            dato: intervenersData
        },
        depositoB: 'S', // "N" if is Welcome Deposit, otherwise "S" //@hardcoded
        descPeriodicidad: depositHireData.get('descPeriodicidad'),
        descPlazo: depositHireData.get('descPlazo'),
        descProducto: depositHireData.get('descProducto'),
        fechaVencimiento: depositHireData.get('fechaVencimiento'),
        gestor: DEPOSIT_HIRE_CONFIRMATION_MANAGER,
        importe,
        importeMax: depositHireData.get('importeMax'),
        importeMin: depositHireData.get('importeMin'),
        indCapit: 'N', // interests capitalization "S" or "N" //@hardcoded
        indRevTo: findElementbyId(cardDetails, 'renewDeposit') ? 'S' : 'N', // Renovation type
        indala01: depositHireData.get('indala01'),
        indala02: depositHireData.get('indala02'),
        indala03: depositHireData.get('indala03'),
        indala04: depositHireData.get('indala04'),
        moneda: depositHireData.get('moneda'),
        numEmp: DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
        perLiq: depositHireData.get('perLiq'),
        plazoPos: depositHireData.get('plazoPos'),
        producto: {
            codigodeestandar: depositProduct.get('standard'),
            subtipodeproducto: {
                subtipodeproducto:
                    depositProduct.get('subtype'),
                tipodeproducto: {
                    tipodeproducto: depositProduct.get('type'),
                }
            }
        },
        saldoCuentaSeleccionada: {
            divisa: immAccount.getIn(['balance', 'currency']),
            importe: immAccount.getIn(['balance', 'amount'])
        },
        tasa: chosenDeposit.depositConfig.tin,
        tipo: depositProduct.get('type')
    };
}

function loadTermsAndConditionsLegalWindow(setTermsAndCondsLegalWindow) {
    setTermsAndCondsLegalWindow();
}

module.exports = {
    deposittransfer_customTagging: customTagging,
    deposittransfer_inputCategoryEvent: inputCategoryEvent,
    deposittransfer_customCategoryEvent: customCategoryEvent,
    deposittransfer_dropdwonAccountShouldBuild: dropdwonAccountShouldBuild,
    deposittransfer_shouldInputSubLabelBuild,
    deposittransfer_validateStep: validateStep,
    deposittransfer_getCustomPrintDocument: getCustomPrintDocument,
    deposittransfer_nextButtonDisabled: nextButtonDisabled,
    deposittransfer_pollingNextButtonDisabled: pollingNextButtonDisabled,
    deposittransfer_loadAmountInputAmounts,
    deposittransfer_shouldBuild: shouldBuild,
    deposittransfer_loan_SignParams: signParams,
    deposittransfer_onChangeAmount: onChangeAmount,
    deposittransfer_setSuccessStepAdditionalInfo: setSuccessStepAdditionalInfo,
    deposittransfer_setSuccessStepPrefix: setSuccessStepPrefix,
    deposittransfer_hireParamsFn: hireParamsFn,
    deposittransfer_loadTermsAndConditionsLegalWindow: loadTermsAndConditionsLegalWindow,
};