import wizardComponentsHelper from '../../utilities/wizardComponenstHelper'
import step1 from './step1'
import step2 from './step2'
import step3 from './step3'
import step4 from './step4'
import successStep from './successStep'
import {
    OPENYOUNG_ACCOUNT_TYPE,
    OPENYOUNG_ACCOUNT_SUBTYPE,
    OPENYOUNG_ACCOUNT_STANDARD
} from 'constants/index'
import { OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS, BANKING_OPEN_YOUNG_ACCOUNT_PRECONTRACTUAL, BANKING_OPEN_YOUNG_ACCOUNT_CONTRACTUAL } from 'constants/hiringProductInformation'
// @ helpers
import { i18n } from 'core/i18n'
import customErrors from './customErrors'
// config
import { stepsWebConfig, stepsCCConfig } from './steps-config'

const { formatText } = i18n;
const stepsConfig = __CONTACT_CENTER__ ? stepsCCConfig : stepsWebConfig;

const contract = {
    ...stepsConfig,
    alertMessage: 'contractsView-juniorAccountAlertMessage',
    alternativePositionedCheckboxes: true,
    biggerLegalTexts: true,
    title: 'contractsView-juniorAccountTitle',
    successStepType: 'dynamic',
    service: '....',
    onCancelURL: '/myprofile/accounts',
    termsAndCondsKey: OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS,
    code_op: 80,
    showIntervenerControls: false,
    SignParamsFn: 'junior_account_SignParams',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-errorBackButtonLabelGlobal',
    errorBackButtonLink: '/myprofile/global',
    hasPolling: true,
    hasDocumentsModal: true,
    accountAlias: OPEN_YOUNG_ACCOUNT_PRODUCT_ALIAS,
    preContractualAlias: BANKING_OPEN_YOUNG_ACCOUNT_PRECONTRACTUAL,
    contractualAlias: BANKING_OPEN_YOUNG_ACCOUNT_CONTRACTUAL,
    type: OPENYOUNG_ACCOUNT_TYPE,
    subtype: OPENYOUNG_ACCOUNT_SUBTYPE,
    standard: OPENYOUNG_ACCOUNT_STANDARD,
    warningContractMessage: 'contractsView-juniorWarningContractMessage',
    operativeName:'operatives-juniorAccount',
    tealiumStepsLabels: [
        'precontractual',
        'holderData',
        'linkedAccount',
        'confirmation'
    ],
    tealiumName: 'contractingAccount',
    customTagging: 'deposittransfer_customTagging',
    confirmationExtraComponents: [
        {
            id_component: 'validation-modal',
            id: 'validationModal',
            title: 'contractsView-juniorAccountModalTitle',
            content: 'juniorAccount_validationModalContent',
            allowContinue: false,
            showCloseButton: true,
            cancelRoute: '/myprofile/accounts'
        }
    ],
    breadcrumb: [
        {
            text: 'breadcrumb-accounts',
            url: '/myprofile/accounts'
        },
        {
            text: 'contractsView-juniorAccountBreadcrumb'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        form_target: 'form_count',
        card_details: [
            {
                id: 'form_count',
                label: '',
                value: [
                    'junior_form_card_owners',
                    'junior_form_personal_data',
                    'junior_form_contact_data',
                    'junior_form_tax_data'
                ],
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            //Forms Values
            {
                id: 'junior_form_card_owners',
                value: [],
                visible: []
            },
            {
                id: 'junior_form_personal_data',
                value: [],
                visible: []
            },
            {
                id: 'junior_form_contact_data',
                value: [],
                visible: []
            },
            {
                id: 'junior_form_tax_data',
                value: [],
                visible: []
            },
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 1,
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    ...(__CONTACT_CENTER__ ? [] : [{ step: 5, order: 1 }])
                ],
                isErrorVisible: false
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                order: 2,
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    },
                    ...(__CONTACT_CENTER__ ? [] : [{ step: 5, order: 1 }])
                ]
            },
            {
                id: 'different_ship_country',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'excludes_id',
                label: '',
                value: [],
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: __CONTACT_CENTER__ ? 4 : 5,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'validationModal',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            ...wizardComponentsHelper.returnObjects(step3)
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-contractsView_openYoungAccountConfirmationCheckboxText'
                        }
                    ]
                }
            ]
        },
        precontractualConfirmationLabel: [
            {
                text: 'contractsView-contractsView_openYoungAccountPrecontractualText'
            }
        ],
        preContractDocuments : {
            preContractPdfFileName: "documentosCuentadeAhorroOpenYoung",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_juniorAccountPdfTitle')
        },
    },
    steps: [
        step1,
        ...(__CONTACT_CENTER__ ? [] : [step2]),
        step3,
        step4
    ],
    successStep,
    customErrors
};

module.exports = {
    contract
};
