const brokerOrdersAndMovementsTags = (eventTag, eventAction) => ({
  rowClick: {
    categoriaEvento: 'broker/ordersAndMovements',
    accionEvento: 'desplegable',
    etiquetaEvento: 'detalles',
  },
  selectContract: {
    categoriaEvento: 'broker/ordesrAndMovements',
    accionEvento: 'desplegable',
    etiquetaEvento: 'seleccionar contrato',
  },
  /* modal Filter */
  cancelButton: {
    categoriaEvento: 'broker/ordersAndMovements',
    accionEvento: 'click',
    etiquetaEvento: 'cancelar',
  },
  verifyButton: {
    categoriaEvento: 'broker/ordersAndMovements',
    accionEvento: 'click',
    etiquetaEvento: 'verificar',
  },
});

module.exports = { brokerOrdersAndMovementsTags };
