// @ vendor
const Immutable = require('immutable');
const { getStore } = require('core/storeHelper');

// @ constants
const actionTypes = require('constants/actionTypes');
const { BROKER_FUND_NEW_CONTRACT_OPTION } = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        accountBalanceInvalid: false,
        accountNumber: '',
        interveners: Immutable.List(),
        intervenersByContract: null,
        intervenerSchemaIsValid: false,
        availableInterveners: Immutable.List(),
        originalInterveners: Immutable.List(),
        amount: 0,
        isSubscription: false,
        minimumAmountIsValid: true,
        amountAccountIsValid: true,
        amountIsValid: true,
        amountDirty: false,
        amountMinimumIsValid: true,
        accountIsValid: true,
        emptyAmount: false,
        userToBlock: null,
        customMinimumPurchaseAmount: {
            amount: 0,
            currency: ''
        },
        accountAmount: {
            amount: 0,
            currency: 'EUR'
        }
    });
}

function minimumAmountIsValid(immState) {
    const amount = parseFloat(immState.get('amount'));
    const exAnteFlag = getStore().getState().flagBasedFeatures.get('wealth_funds_buy_show_costs');
    if (amount >= 0 && __CONTACT_CENTER__ && exAnteFlag) {
        return true;
    }

    if (amount > 0) {
        const minimumAmount = parseFloat(immState.getIn(['customMinimumPurchaseAmount', 'amount']));
        return amount >= minimumAmount;
    }

    return false;
}

function isReady(state) {
    //we get this because parent reducer is not ok, need to be refactored
    const amount = parseFloat(state.get('amount'));
    const accountBalance = parseFloat(state.getIn(['accountAmount','amount']));
    const accountCurrency = (state.getIn(['accountAmount', 'currency']) + '').trim();
    const fundCurrency = (state.getIn(['investmentFunds', 'netAssetValue', 'currency']) + '').trim();
    const accountNumber = state.get('accountNumber');
    const validAccount = state.get('accountIsValid') && accountNumber;
    const isFundAccount = accountNumber && state.get('fundAccounts').includes(accountNumber);
    const isSubscription = state.get('isSubscription');
    const contractNumber = getStore().getState().investmentFunds.getIn(['contractFund','contractNumber']);
    const immBlockedAccounts = getStore().getState().accounts.get('blockedAccountsIds');
    const isAccountSelectedBlocked = immBlockedAccounts.some(blkAcc => blkAcc === accountNumber)
    const exAnteFlag = getStore().getState().flagBasedFeatures.get('wealth_funds_buy_show_costs');
    const allowZero = __CONTACT_CENTER__ && exAnteFlag;
    let validContractNumber, validSchema;
    if(isSubscription){
        validContractNumber = !!contractNumber && contractNumber !== BROKER_FUND_NEW_CONTRACT_OPTION;
        validSchema = true;
    }else{
        validSchema = state.get('intervenerSchemaIsValid');
        validContractNumber = true;
    }
    const validAmountMinimum = minimumAmountIsValid(state);


    const validAmountAccount = allowZero
        ? amount <= accountBalance
        : amount > 0 &&
            (accountCurrency !== fundCurrency || amount <= accountBalance);
    const emptyAmount = allowZero ? false : amount === 0;

    const accountBalanceInvalid = allowZero ? accountBalance < 0 :  accountBalance <= 0;

    const ready = validAmountMinimum &&
        validAmountAccount &&
        validAccount &&
        isFundAccount &&
        !accountBalanceInvalid &&
        !isAccountSelectedBlocked &&
        validSchema &&
        validContractNumber;

    return state.merge({
        valid: ready,
        accountBalanceInvalid: accountBalanceInvalid,
        amountDirty: false,
        amountIsValid: validAmountAccount && validAmountMinimum,
        amountAccountIsValid: validAmountAccount,
        minimumAmountIsValid: validAmountMinimum,
        emptyAmount,
        accountIsValid: state.get('accountIsValid') && !!state.get('accountNumber')
    });
}

function wizardSuscriptionStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_ACCOUNT_NUMBER:
            return state.mergeDeep({
                accountNumber: action.payload.accountId,
                accountAmount: { amount: action.payload.accountAmount },
                accountIsValid: !action.payload.linkedToRobo,
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_AMOUNT:
            return state.merge({
                amount: action.payload.amount,
                amountDirty: true
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_VALIDATE_STEP:
            return isReady(state);
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_IS_SUBSCRIPTION:
            return state.merge({
                isSubscription: true
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CREATE_NEW_CONTRACT:
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_IS_NOT_SUBSCRIPTION:
            return state.merge({
                isSubscription: false
            });
        case actionTypes.BROKER_CLEAR_ALL_FUNDS_CONTRACT_INTERVENERS:
            return state.merge({
                intervenersByContract: null
            });
        case actionTypes.FETCH_BROKER_GET_ALL_FUND_CONTRACT_INTERVENERS_SUCCESS:
            return state.merge({
                intervenersByContract: action.payload.data
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_IS_INVALID_SCHEMA:
            return state.merge({
                intervenerSchemaIsValid: false
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_IS_VALID_SCHEMA:
            return state.merge({
                intervenerSchemaIsValid: true
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_CHANGE_VALIDATE_STEP:
            return isReady(state);
    }

    return state;
}

module.exports = wizardSuscriptionStep1;
