// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const includes = require('lodash/collection/includes');
const moment = require('moment');
// @ utilites
const contractBuilder = require('../routes/contractsView/libs/contractBuilder');
const { scrollToTop } = require('commonsUtilities/scrollUtils');
const UrlHelper = require('utilities/urlHelper');
const { existingJunior } = require('utilities/APIParsingHelper');
const { formatText } = require('core/i18n').i18n;
// @ helpers
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');
const { validateAllComponentsFromStep, validateComponent } = require('../routes/contractsView/utilities/formValidationHelper');
const validateConfirmationCheckboxesForCC = require('routes/contractsView/utilities/contactCenter/confirmationCheckboxesHelper');
const DateHelper = require('utilities/dateHelper');
const { getLanguage } = require('core/i18n').i18n;
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    // Alphabetically ordered
    ACCOUNT_INTERVENTION_AUTHORIZED,
    ACCOUNT_INTERVENTION_AUTHORIZED_CODE,
    ACCOUNT_INTERVENTION_OWNER,
    ACCOUNT_INTERVENTION_OWNER_CODE,
    ACCOUNT_INTERVENTION_REPRESENTATIVE,
    ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE,
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE,
    ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE,
    ACCOUNT_TYPE_E_LOAN,
    CCO_ALREADY_UPDATED,
    DATE_FORMAT_API,
    DEBIT_CARD_TYPE,
    DEFAULT_COUNTRY,
    DEPOSIT_13_MONTHS_CUSTOM_ERROR_NOT_ALLOWED,
    DEPOSIT_13_MONTHS_MAX_AMOUNT_CODE_ERROR,
    DEPOSIT_13_MONTHS_ZERO_AMOUNT_CODE_ERROR,
    HIRE_IMMEDIATE_CREDIT_ERRORS,
    UNEXPECTED_ERROR
} = require('constants/index');
const {
    DEBIT_CARD_MAX_CARDS_EXCEEDED_CODE, DEBIT_CARD_MAX_CARDS_EXCEEDED_CODE_CC
} = require('constants/contractsErrorCodes');
const { DOCUMENT_REQUIRED_NOT_SENT } = require('constants/accountAddDocumentation');
const DateFormatTypes = require('commonsConstants/dateFormatTypes');

// @ lib
const { depositThirteenMonths_isOpenMethodOrNegative } = require('routes/contractsView/libs/depositThirteenMonthsLib');
const { depositnm_showInitialModal, depositnm_showWithoutNewBalanceModal } = require('routes/contractsView/libs/depositnmLib');

function setInitialState() {
    return Immutable.fromJS({
        notificationEventType: '',
        breadcrumb: [],
        hiring_data_contract: {},
        mainDeposits: {},
        mainAccount: {},
        name_contract: '',
        contract_type: '',
        information_step1: null,
        slider_value: 700,
        form_target: null,
        card_details: null,
        interest: {
            isFetching: false,
            isFetched: false,
            calculatedInterest: null,
        },
        depositPolled: '',
        deposit_product: {
            type: null,
            subtype: null,
            depositIdentify: null,
            standard: null,
            preContractualAlias: null,
            contractualAlias: null,
            startDate: null,
            name: null,
            max: null,
            min: null,
            context: null,
            wrapup: null,
        },
        maxAmount: 0,
        newDepositContract: null,
        decreaseAmount: null,
        isInfoModalOpen: false,
        impositionAmount: {
            amount: null,
            currency: null
        },
        limitAccount: {},
        isServiceCalled: false,
        interveners: [],
        availableInterveners: [],
        steps: [{ hasSignaturePattern: true }],
        fromRenewalOrTransferToken: '',
        renewalDepositAccountBlocked: false,
        renewalOrExpiredDepositId: '',
        precontractualDocumentId: '',
        visibleStep: 1,
        immediateCreditError: false,
        inProgress: false,
        isFetching: false,
        isFetchingStep1: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        errorReceived: '',
        result: null,
        response: null,
        error: false,
        getCountriesIsFetching: false,
        conditionDocumentConfirmation: false,
        confirmationCheckboxes: { areValid: false, list: [] },
        precontractualDocumentIdError: false,
        professionCnae: [],
        professionCnaeIsFeching: false,
        professionCno: [],
        professionCnoPrivate: [],
        professionCnaeCategories: [],
        professionCnoIsFeching: false,
        professionCnoPrivateIsFetching: false,
        professionCnoSubcategoriesPublic: [],
        professionCnoSubcategoriesPublicIsFetching: false,
        professionCnoSubcategoriesPrivate: [],
        professionCnoSubcategoriesPrivateIsFetching: false,
        professionCnoPrivateCategoryItems: [],
        professionCnoPublicCategoryItems: [],
        holder_1_professionCnoPublicCategoryItems: [],
        holder_2_professionCnoPublicCategoryItems: [],
        holder_3_professionCnoPublicCategoryItems: [],
        holder_4_professionCnoPublicCategoryItems: [],
        holder_1_professionCnoPrivateCategoryItems: [],
        holder_2_professionCnoPrivateCategoryItems: [],
        holder_3_professionCnoPrivateCategoryItems: [],
        holder_4_professionCnoPrivateCategoryItems: [],
        countries: [],
        professionCnaeUncategorized: [],
        professionCnaeCategoryItems: [],
        professionSectorSubcategories: [],
        professionSectorItems: [],
        dataIsFetching: false,
        depositHireData: {},
        creditCardPending: false,
        creditCardRejected: false,
        shouldShowUpgradeToPayrollPreStep: true,
        juniorData: {
            documentNumber: '',
            documentType: ''
        },
        newAccount: '',
        newAccountProduct: '',
        s_accounts: {
            value: [],
            isFetching: false
        },
        openYoungProfiles: {
            fetched: false,
            list: [],
        },
        openYoungProfileDetails: {
            fetched: false,
            info: {},
            isFetching: false,
        },
        promotionalCodeValid: false,
        promotionalCode: '',
        promotionalCodeAcceptanceCheckboxChecked: false,
        promotionalCodeAcceptanceCheckboxErrorState: false,
        promotionalCodeInvalidErrorState: false,
        promotionalCodeErrorMessage: '',
        promotionalCodeIsDirty: false,
        existingJuniorAccountError: false,
        juniorAccountHiringInitiationPoint: '',
        twoStepValidation: [],
        isInactiveOrNegativeAccount: false,
        document: {
            file: null,
            showErrorMessage: false,
            downloadError: false,
            isFetchingZip: false,
            isFetchingDocuments: false,
            errorDocuments: false
        },
        hasAllAccountsInactive: false
    });
}

function checkIsChild(birth) {
    const adultDate = moment().subtract(18, 'years');
    const childBirthDate = moment(birth, DATE_FORMAT_API);
    return moment(adultDate).isBefore(childBirthDate);
}

function parseOpenYoungProfiles(endpointResponse) {
    return !!endpointResponse && endpointResponse.map(profile => ({
        hasAccounts: get(profile, 'contratosCuenta', []).length > 0,
        hasCards: get(profile, 'contratosTarjeta', []).length > 0,
        isUnder18: checkIsChild(get(profile, 'fechaNacimiento', '')),
        name: trim(get(profile, 'nombre', '')),
        personCode: String(get(profile, 'personaMenor.codigodepersona', '')),
        personType: get(profile, 'personaMenor.tipodepersona', ''),
    }));
}

function parseOpenYoungProfileDetails(profileData) {
    let otherCountriesTaxes = [];
    let phone = '';

    if (!!profileData.listaDispositivos) {
        const phoneData = profileData.listaDispositivos.find((dispositivo) => dispositivo.tipoDispositivo === 'T ') || {};
        phone = trim(phoneData.detalleContacto);
        phone = phone.substr(phone.indexOf('-') + 1)
            .replace(/-/g, '');
    }

    if (!!profileData.listPaisesTributacionAdic) {
        otherCountriesTaxes = profileData.listPaisesTributacionAdic.tributAdicional.map(country => ({
            indTipoTributacion: trim(country.indTipoTributacion),
            paisTributacion: trim(country.paisTributacion),
            tin: {
                codigodocumpersonacorp: trim(country.tin.codigodocumpersonacorp),
                tipodocumpersonacorp: trim(country.tin.tipodocumpersonacorp),
            }
        }));
    }

    return {
        birthCountry: trim(profileData.paisNacimiento) || DEFAULT_COUNTRY,
        birthDate: trim(profileData.fechaNacimiento),
        birthPopulation: trim(profileData.nombrePlaza),
        birthProvince: trim(profileData.provincia.provincia),
        contactInfo: {
            addressOthers: trim(profileData.restoDomicilio),
            country: trim(profileData.paisResidencia),
            phone,
            population: trim(profileData.nombrePlaza),
            postalCode: trim(profileData.codigoPostal),
            province: trim(profileData.plaza.plaza.provincia),
            streetName: trim(profileData.nombreDeVia),
            streetNumber: trim(profileData.numeroDeVia),
            streetType: trim(profileData.tipoDeVia),
        },
        documentNumber: trim(profileData.documentoIdentificacion.codigodocumpersonacorp),
        documentType: trim(profileData.documentoIdentificacion.tipodocumpersonacorp),
        gender: trim(profileData.sexo) === 'H' ? 1 : 0,
        lastName: trim(profileData.apellidoUno.apellido),
        name: trim(profileData.nombreCliente),
        nationality: trim(profileData.paisNacionalidad),
        otherCountriesTaxes,
        secondLastName: trim(profileData.apellidoDos.apellido),
    };
}

function parseInterveners(interveners) {
    return interveners.map(intervener => ({
        name: trim(intervener.name) || trim(intervener.nomInterviniente),
        type: trim(intervener.interventionType) || trim(intervener.tipoIntervencion),

        identification: {
            code: intervener.personCode || (intervener.identifPersona && intervener.identifPersona.codigodepersona) || -1,
            type: trim(intervener.personType) || (intervener.identifPersona && trim(intervener.identifPersona.tipodepersona)) || ''
        }
    }));
}

function parseIntervenersSpecial(interveners, state) {
    const isJuniorAccount = state.get('name_contract') === 'junior-account';
    //Save only Titular interveners if this is the OpenYoung account Flow
    const parsedInterveners = interveners
        .filter(
            intervener =>
                isJuniorAccount ? trim(intervener.tipoIntervencion) === ACCOUNT_INTERVENTION_OWNER_CODE : true
        )
        .map(intervener => {
            const typeNumber = isJuniorAccount ? '09' : trim(intervener.tipoIntervencion) || '';
            return {
                currentIntervener: !!intervener.currentIntervener,
                name: trim(intervener.nombre) || '',
                type: trim(intervener.desIntervencion) || '',
                originalTypeNumber: typeNumber, // This value shouldn't be modified when changing roles
                typeNumber, // This is the value to change when selecting a different role than the one that comes from API
                identification: {
                    code: intervener.numeroPersona || -1,
                    type: trim(intervener.tipoPersona) || ''
                },
                documentNumber: intervener.numeroDocumento,
                interventionOrder: trim(intervener.ordenIntervencion) || '',
                interventionWay: trim(intervener.formaIntervencion) || '',
                relationship: null,
                error: false
            };
        });

    return parsedInterveners;
}

function filterAvailables(currentInterveners = [], originalInterveners = []) {
    const availableInterveners = originalInterveners.filter(intervener => {
        return !currentInterveners.find(originalIntervener => {
            return intervener.get('name') === originalIntervener.get('name');
        });
    });

    return availableInterveners;
}

function parseTramitationExpedient(response) {
    let parsedTramitationExpedient = {
        details: {
            daal: get(response, 'documentacionIntervinienteList[0].daal', '') === DOCUMENT_REQUIRED_NOT_SENT,
            document: get(response, 'documentacionIntervinienteList[0].documento', '') === DOCUMENT_REQUIRED_NOT_SENT,
            receipt: get(response, 'documentacionIntervinienteList[0].recibo', '') === DOCUMENT_REQUIRED_NOT_SENT,
        },
        state: false,
    }

    if (parsedTramitationExpedient.details.daal || parsedTramitationExpedient.details.document || parsedTramitationExpedient.details.receipt) {
        parsedTramitationExpedient.state = true;
    }

    return parsedTramitationExpedient;
}


const updateValue = (state, id, property, newValue) => {
    const foundIndex = state.findIndex(stateObject => stateObject.get('id') === id);

    if (foundIndex < 0) {
        return state;
    }

    return state.update(foundIndex, obj =>
      obj.set(property, newValue),
    );
};
const loadState = state => {
    const newCardDetails = state.card_details;

    if (state.form_target) {
        state.card_details.forEach(component => {
            if (component.target && (component.value || component.value === 0)) {
                newCardDetails
                    .find(targetObject => targetObject.id === component.target)
                    .value.push(component);
            }
        });
    }

    return state;
};

const hadUnexpectedError = response => {
    return response.msg && includes(UNEXPECTED_ERROR, response.msg);
};

const hadErrorAsPerId = response => response.id && response.id === '-1';

function contractsReducer(state = setInitialState(), action) {
    const interveners = state.get('interveners');
    const twoStepValidation = state.get('twoStepValidation');
    let card_details, visibleStep, ret;
    switch (action.type) {
        case actionTypes.CONTRACT_SET_IMPOSITION_AMOUNT:
            return state.merge({
                impositionAmount: {
                    amount: action.payload.amount,
                    currency: action.payload.currency
                }
            })
        case actionTypes.CONTRACT_SET_RENEWAL_DEPOSIT_ID:
            return state.merge({
                renewalOrExpiredDepositId: action.payload.id
            })
        case actionTypes.CONTRACT_SET_RENEWAL_ACCOUNT_BLOCKED:
            return state.merge({
                renewalDepositAccountBlocked: action.payload.isBlocked
            })
        case actionTypes.CONTRACT_SET_RENEWAL_OR_TRANSFER_TOKEN:
            return state.merge({
                fromRenewalOrTransferToken: action.payload.token
            })
        case actionTypes.CONTRACT_SET_MAX_IMPORT_AMOUNT:
            return state.merge({
                maxAmount: action.payload.maxAmount
            })
        case actionTypes.CONTRACT_METHOD_RESET:
            return setInitialState().merge({
                renewalOrExpiredDepositId: state.get('renewalOrExpiredDepositId'),
                fromRenewalOrTransferToken: state.get('fromRenewalOrTransferToken'),
                juniorAccountHiringInitiationPoint: state.get('juniorAccountHiringInitiationPoint'),
                notificationEventType: state.get('notificationEventType')
            });
        case actionTypes.CONTRACT_METHOD_LOAD_STATE:
            return state.mergeDeep(loadState(action.payload));
        case actionTypes.CONTRACT_METHOD_SET_CONTRACT:
            return state.merge({
                hiring_data_contract: action.payload.contract_hiring_data,
                name_contract: action.payload.contract_name,
                contract_type: action.payload.contract_type,
            });
        case actionTypes.CONTRACT_GENERIC_FETCHING:
            return state.merge({
                isFetching: true
            });
        case actionTypes.CONTRACT_GENERIC_LOADING:
            return state.merge({
                isLoading: true
            });
        case actionTypes.CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FETCHING:
            return state.merge({
                isFetchingStep1: true
            });
        case actionTypes.CONTRACT_METHOD_GET_CONTRACT_INFORMATION:
            return state.merge({
                information_step1: action.payload.information,
                isFetchingStep1: false
            });
        case actionTypes.CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE:
            return state.merge({
                isFetchingStep1: false
            });
        case actionTypes.CONTRACT_METHOD_UPDATE_CARD_DETAILS:
            return state.merge({
                card_details: action.payload.card_details
            });
        case actionTypes.CONTRACT_METHOD_UPDATE_CONFIRMATION_CHECKBOXES:
            return state.mergeDeep({
                confirmationCheckboxes: action.payload.confirmationCheckboxes,
                inProgress: true
            });
        case actionTypes.CONTRACT_METHOD_UPDATE_DEPOSIT_PRODUCT:
            return state.mergeDeep({
                deposit_product: action.payload.deposit_product,
            });
        case actionTypes.CONTRACT_METHOD_TOGGLE_INFO_MODAL:
            return state.mergeDeep({
                isInfoModalOpen: !state.get('isInfoModalOpen'),
            });
        case actionTypes.CONTRACT_METHOD_UPDATE_CONDITIONS_CONFIRMATION:
            return state.mergeDeep({
                conditionDocumentConfirmation: action.payload.conditionDocumentConfirmation,
            });
        case actionTypes.CONTRACT_METHOD_IS_SERVICE_CALLED:
            return state.merge({
                isServiceCalled: action.payload.isCalled
            });
        case actionTypes.HIRE_CONTRACT_SUCCESS:
            const debitErrorCode = 'detalleErrorList.detalleErrorList[0].error.codigo';
            const debitErrors = {
                [DEBIT_CARD_MAX_CARDS_EXCEEDED_CODE]: 'maxAllowedNumberOfCardsExceeded',
                [DEBIT_CARD_MAX_CARDS_EXCEEDED_CODE_CC]: 'maxAllowedNumberOfCardsExceeded'
            };
            if (includes(HIRE_IMMEDIATE_CREDIT_ERRORS, action.payload.response)) {
                return state.merge({
                    response: action.payload.response,
                    immediateCreditError: true,
                    inProgress: false,
                    isFetching: false,
                    isLoading: false
                });
            }

            if (action.payload.type && action.payload.type === DEBIT_CARD_TYPE &&
                includes(Object.keys(debitErrors), get(action.payload.response, debitErrorCode))
            ) {
                return state.merge({
                    response: action.payload.response,
                    error: {
                        type: 'custom',
                        errorObject: debitErrors[get(action.payload.response, debitErrorCode)]
                    },
                    inProgress: false
                });
            }

            if (hadUnexpectedError(action.payload.response) || hadErrorAsPerId(action.payload.response)) {
                return state.merge({
                    inProgress: false,
                    isFetching: false,
                    isLoading: false,
                    error: true
                });
            }

            return state.merge({
                response: action.payload.response,
                submitSuccessful: true,
                inProgress: false,
                isFetching: false,
                isLoading: false,
                newAccount: action.payload.response.accountNumber,
                newDepositContract: action.payload.response.depositContract,
            });

        case actionTypes.HIRE_CONTRACT_FAILURE:
            const contractFlow = state.get('name_contract');
            if (includes(HIRE_IMMEDIATE_CREDIT_ERRORS, action.payload.response.description)) {
                return state.merge({
                    response: action.payload.response,
                    immediateCreditError: true,
                    inProgress: false,
                    isFetching: false,
                    isLoading: false
                });
            }
            if (!!action.payload.response.error && !!action.payload.response.error.timeoutError){
                return state.merge({
                    inProgress: false,
                    isFetching: false,
                    isLoading: false,
                    error: {
                        type: 'custom',
                        errorObject: 'timeoutError'
                    }
                })
            }
            if (includes(CCO_ALREADY_UPDATED, action.payload.response.error)) {
                return state.merge({
                    inProgress: false,
                    isFetching: false,
                    isLoading: false,
                    error: {
                        type: 'custom',
                        errorObject: 'requestInProgress'
                    }
                });
            }
            const generalState = {
                response: action.payload.response,
                inProgress: false,
                isFetching: false,
                isLoading: false,
            }
            if (contractFlow === 'checking-account') {
                return state.merge({
                    ...generalState,
                    error: {
                        type: 'custom',
                        errorObject: 'createError'
                    }
                })
            }

            if (contractFlow === 'depositnm' || contractFlow === 'deposit'){
                return state.merge({
                    ...generalState,
                    error: {
                        type: 'custom',
                        errorObject: 'notAllowed'
                    }
                });
            }
            if (contractFlow === 'depositrenewal'){
                return state.merge({
                    ...generalState,
                    error: {
                        type: 'custom',
                        errorObject: 'notAllowed'
                    }
                });
            }

            if (contractFlow === ACCOUNT_TYPE_E_LOAN) {
                return state.merge({
                    response: action.payload.response.description,
                    inProgress: false,
                    error: action.payload.response.error.description,
                    isFetching: false,
                    isLoading: false
                });
            }

            return state.merge({
                response: action.payload.response,
                inProgress: false,
                error: !state.get('existingJuniorAccountError'),
                isFetching: false,
                isLoading: false
            });
        case actionTypes.CONTRACT_METHOD_VALIDATE_STEP:
            const contractName = state.get('name_contract');
            const currentVisibleStep = state.get('visibleStep');
            const stateCardDetails = state.get('card_details');
            const stateConfirmationCheckboxes = state.get('confirmationCheckboxes');
            const steps = state.get('steps');
            const promotionalCodeAcceptanceCheckboxErrorState = state.get(
                'promotionalCodeAcceptanceCheckboxErrorState'
            );
            const stepValidated = validateAllComponentsFromStep(
                steps,
                currentVisibleStep,
                stateCardDetails
            );
            const stateValidated = state.mergeDeep({ card_details: stepValidated.cardDetails });

            const hasCheckboxes = contractBuilder[`${contractName.replace(/-/g, '_')}_hasCheckboxes`];
            const validateStepResult = contractBuilder[`${contractName.replace(/-/g, '_')}_validateStep`](
                currentVisibleStep,
                stateValidated,
                stepValidated.isValid,
                interveners
            );

            ret = validateConfirmationCheckboxesForCC(validateStepResult, stateConfirmationCheckboxes, currentVisibleStep, hasCheckboxes);

            const visaConfirmationModal = ret.card_details
                ? ret.card_details.find(x => x.get('id') === 'creditLimitSelection')
                : null;
            const validationModal = ret.card_details
                ? ret.card_details.find(x => x.get('id') === 'validationModal')
                : null;
            const isValidStep = ret.isValidStep && stepValidated.isValid;
            const commonErrorIds = ['holder-validation-error', 'step2-validation-error', 'step3-validation-error'];
            const generalErrorVisible = ret.card_details
                ? ret.card_details.find(x => commonErrorIds.indexOf(x.get('id')) !== -1 && x.get('isErrorVisible'))
                : false;

            !generalErrorVisible ? !isValidStep && scrollToTop() : !isValidStep && UrlHelper.scrollTo(document.querySelector('.ok-cover'));

            if (
                findElementbyId(stateCardDetails, 'n_steps') === currentVisibleStep || // If showing Last Step -- Hire button OR
                (validationModal && validationModal.get('openModal'))
            ) {
                // If showing the validation modal
                visibleStep = currentVisibleStep;
            } else {
                const requireAPIValidation = state.get('requireValidation')
                    ? state.get('requireValidation').includes(currentVisibleStep)
                    : false;
                visibleStep =
                    isValidStep && !requireAPIValidation
                        ? currentVisibleStep + 1
                        : currentVisibleStep;
            }

            if (visaConfirmationModal && visaConfirmationModal.get('openModal')) {
                visibleStep = currentVisibleStep;
            }

            return state.mergeDeep({
                twoStepValidation: twoStepValidation.push(isValidStep),
                visibleStep,
                isValidStep,
                interveners: ret.interveners || interveners,
                card_details: ret.card_details || stateCardDetails,
                confirmationCheckboxes: ret.confirmationCheckboxes || stateConfirmationCheckboxes,
                promotionalCodeAcceptanceCheckboxErrorState:
                    ret.promotionalCodeAcceptanceCheckboxErrorState ||
                    promotionalCodeAcceptanceCheckboxErrorState
            });
        case actionTypes.CONTRACT_METHOD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.CONTRACT_METHOD_IN_PROGRESS:
            return state.merge({
                inProgress: true
            });
        case actionTypes.CONTRACT_METHOD_NEXT_STEP:
            // This resets the status to closed of the validationModal when changing steps
            const cardDetails = state.get('card_details');
            const newCardDetails = cardDetails.update(
                cardDetails.findIndex(item => item.get('id') === 'validationModal'),
                item => item.set('openModal', false)
            );

            visibleStep = state.get('visibleStep') + 1;

            return state.merge({
                card_details: newCardDetails,
                visibleStep,
                isServiceCalled: true
            });

        case actionTypes.CONTRACT_METHOD_CLOSE_MODAL:
            card_details = updateValue(state.get('card_details'), action.payload.id, 'openModal', false);

            return state.mergeDeep({
                card_details
            });

        case actionTypes.CONTRACT_METHOD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.CONTRACT_METHOD_UPDATE_ACCOUNTS:
            const accounts = contractBuilder[
                state.get('name_contract').split('-').join('_') + '_requestAccountSource'
            ](action.payload.accounts);
            return state.merge({
                s_accounts: accounts
            });
        case actionTypes.FETCH_IMMEDIATE_CREDIT_ACCOUNTS_REQUEST:
            return state;
        case actionTypes.FETCH_IMMEDIATE_CREDIT_ACCOUNTS_SUCCESS:
            return state.merge({ result: action.payload.accounts });
        case actionTypes.FETCH_IMMEDIATE_CREDIT_ACCOUNTS_FAILURE:
            return state.mergeDeep({
                result: null
            });
        //Intervener Manage
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_SUCCESS: {
            const immInterveners = Immutable.fromJS(
                parseInterveners(action.payload.intervenersList)
            );

            return state.merge({
                intervenersModified: false,
                interveners: immInterveners,
                intervenersOriginal: immInterveners
            });
        }
        case actionTypes.FETCH_ACCOUNT_INTERVENERS_RESET: {
            return state.merge({
                interveners: Immutable.List(),
                intervenersOriginal: Immutable.List()
            });
        }
        case actionTypes.CONTRACT_METHOD_RESTORE_DEFAULT_INTERVENERS:
            return state.merge({
                intervenersModified: false,
                interveners: action.payload.originalInterveners,
                availableInterveners: Immutable.List()
            });

        case actionTypes.CONTRACT_METHOD_SET_ACCOUNT_INTERVENER_TYPE:
            let intervenerTypeNumbers = {
                [ACCOUNT_INTERVENTION_AUTHORIZED]: ACCOUNT_INTERVENTION_AUTHORIZED_CODE,
                [ACCOUNT_INTERVENTION_OWNER]: ACCOUNT_INTERVENTION_OWNER_CODE,
                [ACCOUNT_INTERVENTION_REPRESENTATIVE]: ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE,
                [ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE]: ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE
            };

            let intervenerType = {
                [ACCOUNT_INTERVENTION_AUTHORIZED_CODE]: ACCOUNT_INTERVENTION_AUTHORIZED,
                [ACCOUNT_INTERVENTION_OWNER_CODE]: ACCOUNT_INTERVENTION_OWNER,
                [ACCOUNT_INTERVENTION_REPRESENTATIVE_CODE]: ACCOUNT_INTERVENTION_REPRESENTATIVE,
                [ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE_CODE]: ACCOUNT_INTERVENTION_LEGAL_REPRESENTATIVE
            };

            let type = action.payload.type;
            let typeNumber;

            if (action.payload.changeTypeByIntervenerCode) { //Used when intervener type is getting changed by intervener code by setting useIntervenerCode flag as true.
                const intervenerCode = type;
                type = intervenerType[intervenerCode];
                typeNumber = intervenerCode;
            } else {
                typeNumber = intervenerTypeNumbers[type];
            }

            return state.mergeDeep(
                state.setIn(
                    ['interveners', action.payload.index],
                    state.getIn(['interveners', action.payload.index]).merge({
                        type: type,
                        typeNumber: typeNumber
                    })
                ),
                { intervenersModified: true }
            );

        case actionTypes.CONTRACT_METHOD_REMOVE_INTERVENER:
            let nextInterveners = interveners.splice(action.payload.index, 1);
            const availableInterveners = filterAvailables(
                nextInterveners,
                action.payload.originalInterveners
            );

            return state.merge({
                intervenersModified: true,
                interveners: nextInterveners,
                availableInterveners
            });

        case actionTypes.CONTRACT_METHOD_ADD_INTERVENER: {
            let availableInterveners = state.get('availableInterveners');
            const intervener = availableInterveners.last();
            const nextInterveners = interveners.push(intervener);
            availableInterveners = availableInterveners.pop();

            return state.merge({
                interveners: nextInterveners,
                availableInterveners
            });
        }
        case actionTypes.CONTRACT_METHOD_ADD_SIMPLE_INTERVENER:
            return state.merge({
                interveners: interveners.push(
                    Immutable.Map({
                        name: action.payload.names,
                        documentNumber: action.payload.documentNumber,
                        typeNumber: action.payload.type
                    })
                )
            });
        case actionTypes.CONTRACT_METHOD_UPDATE_SIMPLE_INTERVENER:
            const updatedInterveners = state.get('interveners').update(
                state
                    .get('interveners')
                    .findIndex(
                        (intervener) =>
                            intervener.get('documentNumber') ===
                            action.payload.documentNumber
                    ),
                (intervenerToUpdate) =>
                    intervenerToUpdate.set('name', action.payload.names)
            );
            return state.merge({ interveners: updatedInterveners });
        case actionTypes.CONTRACT_METHOD_CLEAR_INTERVENERS:
            return state.merge({
                interveners: []
            });
        case actionTypes.GET_PROFESSION_CNAE:
            var professionCnae = state.get('professionCnae');

            action.items.map(function (value) {
                let items = [];

                value.items.map(function (item) {
                    items.push(
                        Immutable.Map({
                            code: item.id,
                            name: item.description
                        })
                    );
                });

                professionCnae = professionCnae.push(
                    Immutable.Map({
                        category: value.category,
                        subCategory: value.subCategory,
                        items: items
                    })
                );
            });

            return state.merge({
                professionCnae,
                professionCnaeIsFeching: false
            });

        case actionTypes.GET_PROFESSION_CNAE_UNCATEGORIZED_SUCCESS:
            let professionCnaeUncategorized = state.get('professionCnaeUncategorized');

            action.items.map(value => {
                professionCnaeUncategorized = professionCnaeUncategorized.push(
                    Immutable.Map({
                        code: value.id,
                        name: `${value.id} - ${value.description}`
                    })
                );
            });

            return state.merge({
                professionCnaeUncategorized,
                professionCnaeUncategorizedIsFetching: false
            });

        case actionTypes.GET_PROFESSION_CNAE_UNCATEGORIZED_IS_FETCHING:
            return state.merge({
                professionCnaeUncategorizedIsFetching: true
            });

        case actionTypes.GET_PROFESSION_CNAE_IS_FETCHING:
            return state.merge({
                professionCnaeIsFeching: true
            });
        case actionTypes.GET_PROFESSION_CNO:
            var professionCno = state.get('professionCno');

            action.items.map(function (value) {
                professionCno = professionCno.push(
                    Immutable.Map({
                        code: value.id,
                        name: value.description
                    })
                );
            });

            return state.merge({
                professionCno,
                professionCnoIsFeching: false
            });

        case actionTypes.GET_PROFESSION_CNO_PRIVATE:
            const professionCnoPrivate = action.items.map(value =>
                Immutable.Map({
                    code: value.id,
                    name: value.description
                })
            );

            return state.merge({
                professionCnoPrivate,
                professionCnoPrivateIsFetching: false
            });
        case actionTypes.GET_PROFESSION_CNO_IS_FETCHING:
            return state.merge({
                professionCnoIsFeching: true
            });

        case actionTypes.GET_PROFESSION_CNO_PRIVATE_IS_FETCHING:
            return state.merge({
                professionCnoPrivateIsFetching: true
            });

        case actionTypes.REQUEST_GET_COUNTRIES_SUCCESS:
            return state.merge({
                countries: action.payload.countries,
                getCountriesIsFetching: false
            });

        case actionTypes.REQUEST_GET_COUNTRIES_FAILURE:
            return state.merge({
                getCountriesIsFetching: false
                // error: action.payload.error
            });

        case actionTypes.REQUEST_GET_COUNTRIES_IN_PROGRESS:
            return state.merge({
                getCountriesIsFetching: true
            });

        case actionTypes.GET_PROFESSION_CNAE_CATEGORY_SUCCESS:
            const professionCnaeCategories = action.items.map(value =>
                Immutable.Map({
                    value: value.id,
                    label: value.description
                })
            );

            return state.merge({
                professionCnaeCategories,
                professionCnaeCategoriesIsFetching: false
            });

        case actionTypes.GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_IS_FETCHING:
            return state.merge({
                professionCnoSubcategoriesPrivateIsFetching: true
            });

        case actionTypes.GET_PROFESSION_CNO_SUBCATEGORY_PRIVATE_SUCCESS:
            const professionCnoSubcategoriesPrivate = action.items.map(value =>
                Immutable.Map({
                    value: value.id,
                    label: value.description
                })
            );

            return state.merge({
                professionCnoSubcategoriesPrivate,
                professionCnoSubcategoriesPrivateIsFetching: false
            });

        case actionTypes.GET_PROFESSION_CNO_SUBCATEGORY_PUBLIC_IS_FETCHING:
            return state.merge({
                professionCnoSubcategoriesPublicIsFetching: true
            });

        case actionTypes.GET_PROFESSION_CNO_SUBCATEGORY_PUBLIC_SUCCESS:
            const professionCnoSubcategoriesPublic = action.items.map(value =>
                Immutable.Map({
                    value: value.id,
                    label: formatText(`webSharedFiocProfession-${value.description}`)
                })
            );

            return state.merge({
                professionCnoSubcategoriesPublic,
                professionCnoSubcategoriesPublicIsFetching: false
            });

        case actionTypes.GET_PROFESSION_CNO_PUBLIC_ITEM_SUCCESS:
            const professionCnoPublicCategoryItems = action.items.map(value =>
                ({
                    value: value.id,
                    label: formatText(`webSharedFiocProfession-${value.description}`)
                })
            );

            return state.set(`${action.prefix}professionCnoPublicCategoryItems`, professionCnoPublicCategoryItems);

        case actionTypes.GET_PROFESSION_CNO_PRIVATE_ITEM_SUCCESS:
            const professionCnoPrivateCategoryItems = action.items.map(value =>
                ({
                    value: value.id,
                    label: value.description
                })
            );

            return state.set(`${action.prefix}professionCnoPrivateCategoryItems`, professionCnoPrivateCategoryItems);

        case actionTypes.GET_PROFESSION_CNAE_CATEGORY_ITEM_SUCCESS:
            const professionCnaeCategoryItems = action.items.map(value =>
                Immutable.Map({
                    value: value.id,
                    label: `${value.id} - ${value.description}`
                })
            );

            return state.merge({
                professionCnaeCategoryItems
            });

        case actionTypes.GET_SECTOR_SUBCATEGORIES_SUCCESS:
            const professionSectorSubcategories = action.items.map(value =>
                Immutable.Map({
                    value: value.id,
                    label: value.description
                })
            );

            return state.merge({
                professionSectorSubcategories
            });

        case actionTypes.GET_SECTOR_ITEM_SUCCESS:
            const professionSectorItems = action.items.map(value =>
                Immutable.Map({
                    value: value.code,
                    label: value.description
                })
            );

            return state.merge({
                professionSectorItems
            });

        case actionTypes.SET_JUNIOR_DATA_FAILURE:
            return state.merge({
                error: true,
                inProgress: false
            });
        case actionTypes.SET_JUNIOR_DATA_SUCCESS:
            const { documentType, documentNumber, responseCode } = action.payload;
            if (documentNumber === 0) {
                return state.merge({
                    error: true,
                    inProgress: false
                });
            }
            if (existingJunior(responseCode)) {
                card_details = updateValue(
                    state.get('card_details'),
                    'validationModal',
                    'openModal',
                    true
                );
                return state.merge({
                    card_details,
                    existingJuniorAccountError: true,
                    isValidStep: false,
                    twoStepValidation: twoStepValidation.push(false)
                });
            } else {
                card_details = updateValue(state.get('card_details'), 'validationModal', 'openModal', false);
                return state.merge({
                    card_details,
                    juniorData: {
                        documentType,
                        documentNumber
                    },
                    twoStepValidation: twoStepValidation.push(true)
                });
            }

        case actionTypes.SET_JUNIOR_ACCOUNT_HIRING_INITIATION_POINT:
            return state.merge({
                juniorAccountHiringInitiationPoint: action.payload.initiationPoint
            });

        case actionTypes.RESET_JUNIOR_ACCOUNT_HIRING_INITIATION_POINT:
            return state.merge({
                juniorAccountHiringInitiationPoint: ''
            });

        case actionTypes.CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_IS_FETCHING:
        case actionTypes.CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_IS_FETCHING:
        case actionTypes.CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_IS_FETCHING:
            return action.payload.isAccountChange
                ? state
                : state.merge({
                    dataIsFetching: true
                });
        case actionTypes.CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_FAILURE:
        case actionTypes.CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_FAILURE:
        case actionTypes.CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_FAILURE:
            const newDepositHireData = {};
            const errorBody = action.payload.errorBody;
            const maxAmountError = (errorBody.error === DEPOSIT_13_MONTHS_MAX_AMOUNT_CODE_ERROR || errorBody.error === DEPOSIT_13_MONTHS_ZERO_AMOUNT_CODE_ERROR);
            card_details = state.get('card_details');

            if (maxAmountError) {
                card_details = updateValue(card_details, 'accountModal', 'openModal', true);
                newDepositHireData.error = errorBody;
            }

            return state.merge({
                card_details,
                dataIsFetching: false,
                depositHireData: newDepositHireData,
                error: maxAmountError ? false : errorBody
            });

        case actionTypes.CONTRACT_METHOD_13_MONTHS_DEPOSIT_GET_DATA_SUCCESS:
        case actionTypes.CONTRACT_METHOD_18_MONTHS_DEPOSIT_GET_DATA_SUCCESS:
        case actionTypes.CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS:
        case actionTypes.CONTRACT_METHOD_NO_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS:
            const { fechaVencimiento, importeMax, importeMin, perLiq, decreaseAmount = null } = action.payload.response;
            const modalValidation = action.type === actionTypes.CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS ?
                depositnm_showInitialModal(Immutable.fromJS(action.payload.response)) :
                depositThirteenMonths_isOpenMethodOrNegative(Immutable.fromJS(action.payload.response));
            const modalWithoutNewBalanceModalValidation = action.type === actionTypes.CONTRACT_METHOD_NEW_MONEY_DEPOSIT_GET_DATA_SUCCESS ?
            depositnm_showWithoutNewBalanceModal(Immutable.fromJS(action.payload.response)) :
            depositThirteenMonths_isOpenMethodOrNegative(Immutable.fromJS(action.payload.response));

            const transferAmount = state.get('name_contract') === 'depositrenewal'
                ? state.getIn(['impositionAmount', 'amount'])
                : importeMin

            card_details = state
                .get('card_details')
                .update(
                    state.get('card_details').findIndex(obj => obj.get('id') === 'monto_traspasar'),
                    obj2 =>
                        obj2.mergeDeep({
                            min_value: importeMin || 0,
                            max_value: importeMax || 0,
                            default_value: importeMin || 0,
                            money_component_value: importeMin || 0,
                            value: transferAmount || 0,
                        })
                );
            card_details = updateValue(
                card_details,
                'accountModal',
                'openModal',
                modalValidation
            );
            card_details = updateValue(
                card_details,
                'withoutNewBalanceModal',
                'openModal',
                modalWithoutNewBalanceModalValidation
            );
            card_details = updateValue(
                card_details,
                'expiration',
                'value',
                DateHelper.formatDate(fechaVencimiento, DateFormatTypes[getLanguage()].DATE_FORMAT)
            );
            card_details = updateValue(
                card_details,
                'perLiq',
                'value',
                perLiq || ''
            );
            return state.merge({
                dataIsFetching: false,
                depositHireData: action.payload.response,
                card_details,
                decreaseAmount
            });

        case actionTypes.CONTRACT_METHOD_13_MONTHS_DEPOSIT_IS_USER_ABLE_TO_HIRE:
            return !state.get('depositHireData')
                ? state
                : state.mergeDeep({
                    error:
                        state.getIn(['depositHireData', 'bssaldo1']) >= 0
                            ? state.get('error')
                            : DEPOSIT_13_MONTHS_CUSTOM_ERROR_NOT_ALLOWED
                });

        case actionTypes.CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS:
            const depositHireData = state.get('depositHireData').merge(action.payload);
            return state.mergeDeep({
                depositHireData: depositHireData
            });

        case actionTypes.CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE:
            return state.merge({
                error: action.payload.error
            });

        case actionTypes.CONTRACT_METHOD_SET_DEPOSIT_POLLED:
            return state.merge({
                depositPolled: action.payload.deposit,
            });

        case actionTypes.CONTRACT_METHOD_UPDATE_INTERVENERS_SPECIAL:
            const immInterveners = Immutable.fromJS(
                parseIntervenersSpecial(action.payload.inteveners, state)
            ); // typo on API side

            return state.merge({
                intervenersIsFetching: false,
                interveners: immInterveners,
                intervenersOriginal: immInterveners,
                intervenersModified: false
            });

        case actionTypes.CONTRACT_METHOD_UPDATE_INTERVENERS_IN_PROGRESS:
            return state.merge({
                intervenersIsFetching: true
            });

        case actionTypes.CONTRACT_METHOD_UPDATE_INTERVENERS_SPECIAL_FAILURE:
            return state.merge({
                intervenersIsFetching: false,
                interveners: null,
                error: action.payload.error
            });

        case actionTypes.CONTRACT_METHOD_UPDATE_ACCOUNTS_SPECIAL_FAILURE:
            return state.merge({
                s_accounts: {
                    isFetching: false,
                    value: []
                },
                error: action.payload.error
            });

        case actionTypes.CONTRACT_METHOD_UPDATE_ACCOUNTS_SPECIAL:
            const accountsGeneral = action.payload.accounts;
            const datosCCOTitulares = action.payload.response.datosCCOTitulares;
            const accountsSpecial = datosCCOTitulares ? datosCCOTitulares.dato : [];
            const accountsIds = accountsSpecial.map(account => {
                const contractNumber = account.contratoUnificadoPart.numerodecontrato;
                const product = account.contratoUnificadoPart.producto;
                const immAccount = accountsGeneral
                    .get('byId')
                    .find(
                        accountId =>
                            contractNumber === accountId.get('contractNumber') &&
                            product === accountId.get('product')
                    );
                return immAccount ? immAccount.get('accountId') : null;
            });
            let s_accountsValue = {
                byId: {},
                byOrder: accountsIds
            };

            accountsIds.forEach(accountId => {
                s_accountsValue.byId[accountId] = accountsGeneral.getIn(['byId', accountId]);
            });

            return state.merge({
                s_accounts: {
                    value: Immutable.fromJS(s_accountsValue),
                    isFetching: false
                }
            });

        case actionTypes.CONTRACT_METHOD_UPDATE_ACCOUNTS_IN_PROGRESS:
            return state.merge({
                s_accounts: {
                    isFetching: true
                }
            });

        case actionTypes.CONTRACT_METHOD_UPDATE_INTERVENER_RELATIONSHIP:
            const { index, relationship } = action.payload;
            return state.merge({
                interveners: state
                    .get('interveners')
                    .mergeIn([index], { relationship, error: false }),
                intervenersModified: true
            });

        case actionTypes.CONTRACT_METHOD_EXCLUDE_INTERVENER:
            return state.merge({
                interveners: state.get('interveners').delete(action.payload.index),
                intervenersModified: true
            });

        case actionTypes.HIRE_ACCOUNT_SUCCESS:
            if (
                hadUnexpectedError(action.payload.response) ||
                !action.payload.response.numeroCuenta
            ) {
                return state.merge({
                    inProgress: false,
                    isFetching: false,
                    isLoading: false,
                    error: true
                });
            }

            return state.merge({
                newAccount: action.payload.response.numeroCuenta,
                submitSuccessful: true,
                inProgress: false,
                isFetching: false,
                isLoading: false
            });

        case actionTypes.HIRE_ACCOUNT_SET_PIN_SUCCESS:
            card_details = updateValue(
                state.get('card_details'),
                'card_pin_change',
                'showErrorInSummary',
                false,
            );

            return state.merge({
                card_details,
            });

        case actionTypes.HIRE_ACCOUNT_SET_PIN_ERROR:
            card_details = updateValue(
                state.get('card_details'),
                'card_pin_change',
                'showErrorInSummary',
                true,
            );

            return state.merge({
                card_details,
            });

        case actionTypes.HIRE_ACCOUNT_GET_TRAMITATION_EXPEDIENT_ERROR:
            card_details = updateValue(
                state.get('card_details'),
                'tramitation_expedient',
                'addDocumentationError',
                true,
            );

            card_details = updateValue(
                card_details,
                'tramitation_expedient',
                'showAddDocumentation',
                true,
            );

            return state.merge({
                card_details,
            });

        case actionTypes.HIRE_ACCOUNT_GET_TRAMITATION_EXPEDIENT_SUCCESS:
            const tramitationExpedientDetails = parseTramitationExpedient(action.payload.response);

            card_details = updateValue(
                state.get('card_details'),
                'tramitation_expedient',
                'details',
                tramitationExpedientDetails.details,
            );

            card_details = updateValue(
                card_details,
                'tramitation_expedient',
                'showAddDocumentation',
                tramitationExpedientDetails.state,
            );

            return state.merge({
                card_details,
            });

        case actionTypes.CONTRACT_METHOD_DELETE_ADDITIONAL_TAX_COUNTRY:
            const targetIndex = state
                .get('card_details')
                .findIndex(immTarget => immTarget.get('id') === 'junior_form_tax_data');
            return state.mergeIn(
                ['card_details', targetIndex, 'value', 1],
                Immutable.fromJS({ value: action.payload.countries })
            );

        case actionTypes.CONTRACT_METHOD_SET_CARD_IMAGE:
            card_details = updateValue(
                state.get('card_details'),
                'card_image',
                'value',
                action.payload.cardImage
            );
            return state.merge({
                card_details
            });

        case actionTypes.CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_IN_PROGRESS:
            return state.merge({
                isFetching: true
            });

        case actionTypes.CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_SET_DIRTY:
            return state.merge({
                promotionalCodeIsDirty: true
            });

        case actionTypes.CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_SUCCESS:
            return state.merge({
                isFetching: false,
                promotionalCode: action.payload.response.promotionalCode,
                promotionalCodeValid: true,
                promotionalCodeInvalidErrorState: false,
                promotionalCodeErrorMessage: '',
                promotionalCodeIsDirty: false
            });

        case actionTypes.CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_FAILURE:
            return state.merge({
                isFetching: false,
                promotionalCodeValid: false,
                promotionalCodeInvalidErrorState: true,
                promotionalCode: action.payload.showPromoCodeOnFailure ? state.get('promotionalCode') : '',
                promotionalCodeErrorMessage: action.payload.error,
                promotionalCodeIsDirty: false
            });

        case actionTypes.CONTRACT_METHOD_VALIDATE_PROMOTIONAL_CODE_ACCEPTANCE_CHECKED:
            return state.merge({
                promotionalCodeAcceptanceCheckboxChecked: action.payload.checked,
                promotionalCodeAcceptanceCheckboxErrorState: !action.payload.checked ? false : null
            });

        case actionTypes.CONTRACT_METHOD_CANCEL_PROMOTIONAL_CODE:
            return state.merge({
                promotionalCode: '',
                promotionalCodeValid: false,
                promotionalCodeIsDirty: false
            });

        case actionTypes.FIOC_UPDATE_SUCCESS:
            return state.merge({
                response: action.payload.response,
                submitSuccessful: true,
                inProgress: false,
                isFetching: false,
                isLoading: false
            });

        case actionTypes.FIOC_UPDATE_FAILURE:
            return state.merge({
                response: action.payload.error,
                inProgress: false,
                error: true,
                isFetching: false,
                isLoading: false
            });

        case actionTypes.UPDATE_SHOULD_SHOW_UPGRADE_TO_PAYROLL_PRE_STEP:
            return state.merge({
                shouldShowUpgradeToPayrollPreStep: action.payload.shouldShowUpgradeToPayrollPreStep
            });

        case actionTypes.SET_PAYROLL_ACCOUNT_PROMO_CODE:
            return state.merge({
                promotionalCode: action.payload
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_FAILURE:
            return state.merge({
                dataIsFetching: false,
                error: true,
                response: action.payload.error,
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_REQUEST:
            return state.merge({
                dataIsFetching: true,
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILES_FOR_CONTRACT_SUCCESS:
            return state.merge({
                dataIsFetching: false,
                openYoungProfiles: {
                    fetched: true,
                    list: parseOpenYoungProfiles(action.payload.profiles),
                }
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_REQUEST:
            return state.merge({
                openYoungProfileDetails: {
                    fetched: false,
                    info: {},
                    isFetching: true,
                }
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_SUCCESS:
            return state.merge({
                openYoungProfileDetails: {
                    fetched: true,
                    info: parseOpenYoungProfileDetails(action.payload.profileData),
                    isFetching: false,
                }
            });

        case actionTypes.OPEN_YOUNG_GET_PROFILE_DETAILS_FOR_CONTRACT_FAILURE:
            return state.merge({
                error: true,
                openYoungProfileDetails: {
                    fetched: false,
                    info: {},
                    isFetching: false,
                }
            });

        case actionTypes.CONTRACT_METHOD_GET_HIRING_DOCUMENTS:
            return state.mergeDeep({
                document: {
                    isFetchingDocuments: true,
                    errorDocuments: false
                }
            });

        case actionTypes.CONTRACT_METHOD_GET_HIRING_DOCUMENTS_SUCCESS:
            return state.mergeDeep({
                document: {
                    isFetchingDocuments: false,
                    file: action.payload.response,
                    errorDocuments: false
                }
            });

        case actionTypes.CONTRACT_METHOD_GET_HIRING_DOCUMENTS_ERROR:
            return state.mergeDeep({
                document: {
                    isFetchingDocuments: false,
                    errorDocuments: true
                }
            });

        case actionTypes.CONTRACT_METHOD_DOWNLOAD_DOCUMENT:
            return state.mergeDeep({
                document: {
                    isFetchingZip: true
                }
            });

        case actionTypes.CONTRACT_METHOD_DOWNLOAD_DOCUMENT_SUCCESS:
            return state.mergeDeep({
                document: {
                    isFetchingZip: false,
                    downloadError: false
                }
            });

        case actionTypes.CONTRACT_METHOD_DOWNLOAD_DOCUMENT_ERROR:
            return state.mergeDeep({
                document: {
                    isFetchingZip: false,
                    downloadError: true
                }
            });
        case actionTypes.CONTRACT_METHOD_RESET_DOCUMENTS_AGREE:
            return state.mergeDeep({
                confirmationCheckboxes: {
                    areValid: false
                },
                document: {
                    showErrorMessage: false
                }
            });
        case actionTypes.CONTRACT_METHOD_TOGGLE_AGREE:
            return state.mergeDeep({
                confirmationCheckboxes: {
                    areValid: !state.getIn(['confirmationCheckboxes', 'areValid'])
                },
                document: {
                    showErrorMessage: false
                }
            });
        case actionTypes.CONTRACT_HIRE_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                hasAllAccountsInactive: true
            });

        case actionTypes.OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_FAILURE:
            card_details = updateValue(state.get('card_details'), 'input-number-documents', 'isJuniorFetching', false);
            return state.mergeDeep({
                card_details,
            });

        case actionTypes.OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_SUCCESS:
            card_details = updateValue(state.get('card_details'), 'input-number-documents', 'isErrorVisible', action.payload.isOyAccountHired);
            card_details = card_details && card_details.toJS();
            const component = card_details.find(cardDetailsItem => cardDetailsItem.id === 'input-number-documents');

            validateComponent(
                card_details,
                component,
                state.get('steps'),
                state.get('visibleStep')
            );

            return state.merge({
                card_details
            });

        case actionTypes.OPEN_YOUNG_GET_JUNIOR_PRODUCT_DATA_FOR_CONTRACT_IS_FETCHING:
            card_details = updateValue(state.get('card_details'), 'input-number-documents', 'isJuniorFetching', true);
            return state.mergeDeep({
                card_details,
            });

        case actionTypes.SET_MAIN_DEPOSITS_REQUEST:
            return state.mergeDeep({
                mainDeposits: {
                    isFetching: true,
                    success: false,
                },
                dataIsFetching: true,
            });

        case actionTypes.SET_MAIN_DEPOSITS_FAILURE:
            return state.mergeDeep({
                mainDeposits: {
                    isFetching: false,
                    error: true,
                    success: false
                },
                dataIsFetching: false,
            });

        case actionTypes.SET_MAIN_DEPOSITS_SUCCESS:
            return state.mergeDeep({
                mainDeposits: {
                    isFetching: false,
                    success: true,
                    data: action.payload.response
                },
                dataIsFetching: false
            });

        case actionTypes.SET_MAIN_ACCOUNT_REQUEST:
            return state.mergeDeep({
                mainAccount: {
                    isFetching: true,
                    success: false,
                },
                dataIsFetching: true,
            });

        case actionTypes.SET_MAIN_ACCOUNT_FAILURE:
            return state.mergeDeep({
                mainAccount: {
                    isFetching: false,
                    error: true,
                    success: false
                },
                dataIsFetching: false,
            });

        case actionTypes.SET_MAIN_ACCOUNT_SUCCESS:
            return state.mergeDeep({
                mainAccount: {
                    isFetching: false,
                    success: true,
                    data: action.payload.response
                },
                dataIsFetching: false
            });
        
        case actionTypes.SET_LIMIT_ACCOUNT_REQUEST:
            return state.mergeDeep({
                limitAccount: {
                    isFetching: true,
                    success: false,
                }
            });

        case actionTypes.SET_LIMIT_ACCOUNT_FAILURE:
            let error = false
            if (!action.payload.hasLimit) {
                error = {
                    type: 'custom',
                    errorObject: 'notAllowed'
                }
            }
            return state.mergeDeep({
                error,
                isFetching: false,
                limitAccount: {
                    hasLimit: action.payload.hasLimit
                }
            });

        case actionTypes.SET_LIMIT_ACCOUNT_SUCCESS:
            return state.mergeDeep({
                limitAccount: {
                    isFetching: false,
                    success: true,
                }
            });

        case actionTypes.SET_PRECONTRACTUAL_DOCUMENT_URL:
            return state.merge({
                precontractualDocumentUrl: action.payload.response,
                precontractualDocumentUrlError: false,
            })
        case actionTypes.GET_PRECONTRACTUAL_DOCUMENT_URL_FAILURE:
                return state.merge({
                    precontractualDocumentUrlError: true,
        })
        case actionTypes.SET_PRECONTRACTUAL_DOCUMENT_ID:
            return state.merge({
                precontractualDocumentId: action.payload.response,
                precontractualDocumentIdError: false,
            })
        case actionTypes.GET_PRECONTRACTUAL_DOCUMENT_ID_FAILURE:
            return state.merge({
                precontractualDocumentIdError: true,
            })
        case actionTypes.CONTRACTUAL_POLLING_INIT_REQUEST:
            return state.merge({
                isLoading: true,
            })
        case actionTypes.CONTRACTUAL_POLLING_DOCUMENTS_SUCCESS:
            return state.merge({
                isLoading: false,
            })
        case actionTypes.CONTRACT_DEPOSITS_INTEREST_FETCHING:
            return state.mergeDeep({
                interest: {
                    isFetching: true,
                    isFetched: false,
                    error: null,
                }
            })
        case actionTypes.CONTRACT_DEPOSITS_INTEREST_SUCCESS:
            return state.mergeDeep({
                interest: {
                    isFetching: false,
                    isFetched: true,
                    calculatedInterest: action.payload.calculatedInterest,
                }
            })
        case actionTypes.CONTRACT_DEPOSITS_INTEREST_FAILURE:
            return state.mergeDeep({
                interest: {
                    isFetching: false,
                    error: action.payload.interestError,
                    calculatedInterest: null
                }
            })
        default:
            return state;
    }
}

module.exports = contractsReducer;
