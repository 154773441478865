// @ vendor
const Immutable = require('immutable');
const moment = require('moment');
const { getStore } = require('core/storeHelper');

// @ constants
const actionTypes = require('constants/actionTypes');

// @ utilities
const DateHelper = require('utilities/dateHelper');
const { formatText } = require('core/i18n').i18n;

function setInitialState() {
    const firstDay = moment().startOf('day');
    const limitDate = DateHelper.getNextBussinessDay(false, firstDay);
    const initialDate = limitDate
    return Immutable.Map().merge({
        valid: false,
        disabledButton: false,
        amount: 0,
        customMinimumAdditionalPurchaseAmount: {amount: 0, currency: 'EUR'},
        amountErrorState: false,
        amountErrorMsg: '',
        selectedDate: initialDate,
        minDate: limitDate,
        isValidDate: true,
        periodicityLabel: 'Mensual',
        periodicityValue: 'Monthly',
        periodicityMinimum: 0,
        accountNumber: '',
        interveners: Immutable.List(),
        isSubscription: false,
        amountIsValid: true,
        amountDirty: false,
        periodicityOptions: [
            {
                label: 'brokerContributionFundStep1-weeklyPeriodicity',
                frecuenciaCuotaCada: 1,
                frecuenciaCuotaCadaUnidadDeTiempo: 2,
                value: 'Weekly'
            },
          {
              label: 'brokerContributionFundStep1-monthlyPeriodicity',
              frecuenciaCuotaCada: 1,
              frecuenciaCuotaCadaUnidadDeTiempo: 2,
              value: 'Monthly'
          },
          {
              label: 'brokerContributionFundStep1-quarterlyPeriodicity',
              frecuenciaCuotaCada: 3,
              frecuenciaCuotaCadaUnidadDeTiempo: 2,
              value: 'Quarterly'
          },
          {
              label: 'brokerContributionFundStep1-semestralPeriodicity',
              frecuenciaCuotaCada: 6,
              frecuenciaCuotaCadaUnidadDeTiempo: 2,
              value: 'Semiannual'
          },
          {
              label: 'brokerContributionFundStep1-annualPeriodicity',
              frecuenciaCuotaCada: 1,
              frecuenciaCuotaCadaUnidadDeTiempo: 3,
              value: 'Annual'
          }
      ],
    });
}
function isReady(state, value) {
    const amount = value !== undefined ? parseFloat(value) : parseFloat(state.get('amount'));
    const minimumAmount = getStore()
        .getState()
        .investmentFundsWithContribution
        .getIn([
            'steps',
            0,
            'investmentFunds',
            'customMinimumPurchaseAmount',
            'additionalSubscriptionAmount'
        ], '0');
    const validAmountMinimum = (amount >= minimumAmount) && (amount > 0);
    const isValidDate = state.get('isValidDate')
    const ready = validAmountMinimum && isValidDate

    return state.merge({
        valid: ready,
        amount: amount,
        amountDirty: true,
        amountErrorState: validAmountMinimum ? false : true,
        amountErrorMsg: validAmountMinimum ? null : formatText('brokerContributionFundStep1-minimumAmountError')
    });
}

function wizardPeriodicalContributionStep(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_AMOUNT:
            return isReady(state, action.payload);
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_SET_CUSTOM_MINIMUM_ADDITIONAL_PURCHASE_AMOUNT:
            return state.merge({
                customMinimumAdditionalPurchaseAmount: action.payload,
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_PERIODICITY:
            return state.merge({
                periodicityValue: action.payload.value,
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_DATE:
            return state.merge({
                selectedDate: action.payload,
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SKIP_CONTRIBUTION_STEP:
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_CHANGE_VALIDATE_STEP:
            return state.merge({
                valid: true,
            });
    }

    return state;
}

module.exports = wizardPeriodicalContributionStep;
