module.exports = {
    title: 'contractsView-contractsView_precontractualConditionsTitle',
    order: 3,
    footer_text: '',
    position: 'middle',
    disableNext: 'eAccount_pollingNextButtonDisabled',
    on_next_click: 'eAccount_loadTermsAndConditionsLegalWindow',
	components: [
        {
            id_component: 'step2-dic-viewer',
        }
	]
};
