module.exports = {
    title: 'contractsView-contractsView_precontractualConditionsTitle',
    order: 2,
    footer_text: '',
    position: 'middle',
    disableNext: 'depositExpired_pollingNextButtonDisabled',
    on_next_click: 'depositExpired_loadTermsAndConditionsLegalWindow',
	components: [
        ...(
                __CONTACT_CENTER__ ?
                [
                    {
                        id_component: 'legal-information',
                        legal_text: 'depositos-depositos_postMessiLegalTextCC',
                        legal_text_passport: 'depositos-depositos_postMessiLegalTextPassportCC',
                    }
                ] : [
                    {
                        id_component: 'step2-dic-viewer',
                    }
                ]
            )
	]
};
