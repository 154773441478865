const brokerInvestmentContributionsTags = (eventTag, eventAction) => ({
  /* Contribution management list */
  myInvestementButton: {
    categoriaEvento: 'gestion aportaciones robo',
    accionEvento: 'clickButton',
    etiquetaEvento: 'ir a mis inversiones',
  },
  newContributionButton: {
    categoriaEvento: 'gestion aportaciones robo',
    accionEvento: 'clickButton',
    etiquetaEvento: 'nueva aportacion',
    productName: eventTag?.name,
    productID: eventTag?.isin,
  },
  updateContributionButton: {
    categoriaEvento: 'gestion aportaciones robo',
    accionEvento: 'clickButton',
    etiquetaEvento: 'modificar',
    productName: eventTag?.name,
    productID: eventTag?.isin,
  },
  cancelContributionButton: {
    categoriaEvento: 'gestion aportaciones robo',
    accionEvento: 'clickButton',
    etiquetaEvento: 'cancelar',
    productName: eventTag?.name,
    productID: eventTag?.isin,
  },
  /* new contribution */
  contributionFundStep2: {
    event_name: 'event_success',
    action: 'success',
    format: 'steps',
    component: 'funnel',
    element: 'investmentDetails',
  },
  onSuccessNewContrib: {
    event_name: 'event_success',
    action: 'success',
    format: 'steps',
    component: 'funnel',
    element: 'termsConditions',
  },
  goToMovementsSuccess: {
    categoriaEvento: 'transfer/fund',
    accionEvento: 'success',
    etiquetaEvento: 'consultar movimientos',
    productName: eventTag?.name,
    productID: eventTag?.isin,
  },
  goToWallet: {
    categoriaEvento:"transfer/fund",
    accionEvento:"success",
    etiquetaEvento:"volver a mi cartera",
    productName: eventTag?.name,
    productID: eventTag?.isin,
    },
    /* cancel contribution */
    cancelButton: {
    categoriaEvento:"cancelFundsPeriodicContribution",
    accionEvento:"click",
    etiquetaEvento:"cancel",
    productName: eventTag?.name,
    productID: eventTag?.isin,
    },
    backButton: {
    categoriaEvento:"cancelFundsPeriodicContribution",
    accionEvento:"click",
    etiquetaEvento:"back",
    productName: eventTag?.name,
    productID: eventTag?.isin,
    },
    hireButton: {
    categoriaEvento:"cancelFundsPeriodicContribution",
    accionEvento:"click",
    etiquetaEvento:"hire",
    productName: eventTag?.name,
    productID: eventTag?.isin,
    }
    
});

module.exports = { brokerInvestmentContributionsTags };
