
const brokerFundsTags = (eventTag, eventAction) => (
    {
        brokerPopular: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: `mostPopular - ${eventAction}Funds`,
            etiquetaEvento: eventTag
        },
        searchButton: {
            categoriaEvento: 'buscador/simple',
            accionEvento: 'inicio',
            etiquetaEvento: 'funds'
        },
        buyButton: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'buy',
            etiquetaEvento: 'funds'
        },
        buyButtonComCard: {
            categoriaEvento: 'fundsHomeInteraction',
            accionEvento: 'comercialCard',
            etiquetaEvento: 'buy'
        },
        comercialCard: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'ComercialCard',
            etiquetaEvento: eventTag
        },
        tooltip: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'mostPopular',
            etiquetaEvento: 'Tooltip'
        },
        buildPortfolio: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'mostPopular',
            etiquetaEvento: 'BuildYourPortfolio'
        },
        fundsSearch: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'mostPopular',
            etiquetaEvento: 'fundsSearch'
        },
        cnmvLink: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'riskIndicator',
            etiquetaEvento: 'www.cnmv.es'
        },
        openBankLink: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'riskIndicator',
            etiquetaEvento: 'www.openbank.es'
        },
        sliderProducts: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'products'
        },
        sliderContracts: {
            categoriaEvento: 'fundHomeInteraction',
            accionEvento: 'sliderMenu',
            etiquetaEvento: 'contracts'
        },
        rentabilityTooltip: {
            categoriaEvento: 'searchInteraction',
            accionEvento: 'rentabilityTooltip',
            etiquetaEvento: eventTag
        },
        searchInteractionLink: {
            categoriaEvento: 'searchInteraction',
            accionEvento: `see ${eventAction}`,
            etiquetaEvento: eventTag
        },
        searchInteractionButton: {
            categoriaEvento: 'searchInteraction',
            accionEvento: `buy ${eventAction}`,
            etiquetaEvento: eventTag
        },
        buyFundStep1: {
            categoriaEvento: 'proc-broker-comprar-fondo-inversion',
            accionEvento: 'inicio',
            etiquetaEvento: eventTag
        },
        dataProtectionLinkStep1: {
            categoriaEvento: 'fundBuyInteraction',
            accionEvento: 'dataProtection',
            etiquetaEvento: 'step 1'
        },
        buyFundStep2: {
            categoriaEvento: 'proc-broker-comprar-fondo-inversion',
            accionEvento: 'paso_2',
            etiquetaEvento: eventTag
        },
        buyFundStepSuccess: {
            event_name: "event_success",
            action: "success",
            format: "steps",
            component: "funnel",
            element: eventTag
        },
        GoToSearchButton: {
            categoriaEvento: 'fundBuyInteraction',
            accionEvento: 'goToSearch',
            etiquetaEvento: eventTag
            },
        buttonLegalDocs_DFI: {
            categoriaEvento: 'fundBuyInteraction',
            accionEvento: 'keyData',
            etiquetaEvento: 'read'
        },
        buttonLegalDocs_ECONOMIC_REPORT: {
            categoriaEvento: 'fundBuyInteraction',
            accionEvento: 'lastEconomicReport',
            etiquetaEvento: 'read'
        },
        buttonLegalDocs_MARKETING_REPORT: {
            categoriaEvento: 'fundBuyInteraction',
            accionEvento: 'marketingReport',
            etiquetaEvento: 'read'
        },
        buttonLegalDocs_COST: {
            categoriaEvento: 'fundBuyInteraction',
            accionEvento: 'fees',
            etiquetaEvento: 'read'
        }
    }
)

module.exports = { brokerFundsTags }