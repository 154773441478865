// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    FOURTH_STEP,
    SECOND_STEP,
    THIRD_STEP,
    FIFTH_STEP
} = require('constants/index');

// @reducers
const hireCardViaTStep1 = require('./hireCardViaT/hireCardViaTStep1');
const hireCardViaTStep2 = require('./hireCardViaT/hireCardViaTStep2');
const hireCardViaTStep3 = require('./hireCardViaT/hireCardViaTStep3');
const hireCardOpenCreditStepINE = require('./hireCardOpenCredit/hireCardOpenCreditStepINE');
const hireCardViaTStep4 = require('./hireCardViaT/hireCardViaTStep4');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    error: false,
    errorMsg: '',
    hiringStatus: '',
    isFetching: false,
    steps: [
        hireCardViaTStep1(),
        hireCardViaTStep2(),
        hireCardViaTStep3(),
        hireCardViaTStep4()
    ],
    newSteps: [
        hireCardViaTStep1(),
        hireCardViaTStep2(),
        hireCardViaTStep3(),
        hireCardOpenCreditStepINE(),
        hireCardViaTStep4()
    ],
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    loadingFlag: false,
    documentINE: false,
    errorINE: false,
    errorIinit: false,
    scrollDown: false,
    agreeIneCheckbox: false,
    checkButtonRadio: false,
    checkButtonRadioPolicy: false,
    printDocument: false,
    printDocumentPolicy: false,
    cardsDocumentsPrintedAndSigned: undefined,
    cardsDocumentsPrintedAndSignedPolicy: null,
    mailSent: false,
    checkboxChecked: false,
    hasBeenSentEmail: false,
    scrollDownPolicy: false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireCardViaTStep1(step, action);
            case SECOND_STEP:
                return hireCardViaTStep2(step, action);
            case THIRD_STEP:
                return hireCardViaTStep3(step, action);
            case FOURTH_STEP:
                return hireCardViaTStep4(step, action);
        }
    });
}
function updateNewStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireCardViaTStep1(step, action);
            case SECOND_STEP:
                return hireCardViaTStep2(step, action);
            case THIRD_STEP:
                return hireCardViaTStep3(step, action);
            case FOURTH_STEP:
                return hireCardOpenCreditStepINE(step, action);
            case FIFTH_STEP:
                return hireCardViaTStep4(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps) {
        nextStep = currentStep;
    }

    return nextStep;
}

function hireCardViaTReducer(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_CARD_VIA_T_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 4:
                    partialSteps = updateStep(FOURTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        case actionTypes.HIRE_CARD_VIA_T_NEW_VALIDATE_STEP:
            switch (action.payload.step) {
                case 1:
                    partialSteps = updateNewStep(FIRST_STEP, state.get('newSteps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, action.payload.step);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 2:
                    partialSteps = updateNewStep(SECOND_STEP, state.get('newSteps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, action.payload.step);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 3:
                    partialSteps = updateNewStep(THIRD_STEP, state.get('newSteps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, action.payload.step);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 4:
                    partialSteps = updateNewStep(FOURTH_STEP, state.get('newSteps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, action.payload.step);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });

                case 5:
                    partialSteps = updateStep(FIFTH_STEP, state.get('newSteps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, action.payload.step);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        case actionTypes.HIRE_CARD_CREDIT_INIT_SUCCESS:
            return state.merge({
                operationProcessIdInit: action.payload.response.operationProcessId,
                contractNumberAccount: action.payload.contractNumberAccount,
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_ERROR:
            return state.merge({
                error: true,
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_POLICY_SUCCESS:
            return state.merge({
                operationProcessIdInitPolicy: action.payload.response.operationProcessId,
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_POLICY_ERROR:
            return state.merge({
                error: true,
            });
        case actionTypes.HIRE_CARD_CREDIT_INE_SUCCESS:
            return state.merge({
                documentINE: action.payload.base64,
                documentIdINE: action.payload.documentId,
            });
        case actionTypes.HIRE_CARD_CREDIT_INE_ERROR:
            return state.merge({
                error: true,
            });
        case actionTypes.HIRE_CARD_CREDIT_POLICY_SUCCESS:
            return state.merge({
                documentPolicy: action.payload.base64,
                documentIdPolicy: action.payload.documentId,
            });
        case actionTypes.HIRE_CARD_CREDIT_POLICY_ERROR:
            return state.merge({
                error: true,
            });
        case actionTypes.CARDS_REQUEST_CONFIRM_SCROLL_DOWN:
            return state.merge({
                scrollDown: true,
            });
        case actionTypes.CARDS_REQUEST_CONFIRM_SCROLL_DOWN_POLICY:
            return state.merge({
                scrollDownPolicy: true,
            });
        case actionTypes.RESET_INIT_CARDS_DOCUMENTS:
            return state.merge({
                documentINE: false,
                documentPolicy: false,
                scrollDown: false,
                agreeIneCheckbox: false
            });
        case actionTypes.CARDS_CHECKED_DOCUMENT:
            return state.merge({
                agreeIneCheckbox: !state.get('agreeIneCheckbox'),
            });
        case actionTypes.HIRE_CARD_VIA_T_PROCCESS_EMAIL_SUCCESS:
            return state.merge({
                mailSent: true,
                hasBeenSentEmail: true,
                isFetching: false
            });
        case actionTypes.HIRE_CARD_VIA_T_PROCCESS_EMAIL_ERROR:
            return state.merge({
                error: true,
            });
        case actionTypes.HIRE_CARD_LOADING:
            return state.merge({
                loadingFlag: action.payload.loadingFlag
            });
        case actionTypes.HIRE_CARD_VIA_T_SET_ACCOUNT:
        case actionTypes.HIRE_CARD_VIA_T_VALIDATE_ACCOUNT:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action),
                newSteps: updateStep(SECOND_STEP, state.get('newSteps'), action)
            });

        case actionTypes.HIRE_CARD_VIA_T_SET_AGREEMENT_CHECKBOX:
            const { stepToUpdate = __CONTACT_CENTER__ ? FIRST_STEP : FOURTH_STEP } = action.payload;
            return state.merge({
                steps: updateStep(stepToUpdate, state.get('steps'), action),
            });

        case actionTypes.HIRE_CARD_VIA_T_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.HIRE_CARD_VIA_T_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.HIRE_CARD_VIA_T_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });

        case actionTypes.HIRE_CARD_VIA_T_SUCCESS:
            return state.merge({
                hiringStatus: action.payload.hiringStatus,
                isFetching: false,
                submitSuccessful: true
            });

        case actionTypes.HIRE_CARD_VIA_T_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                hiringStatus: action.payload.hiringStatus,
                isFetching: false
            });

        case actionTypes.HIRE_CARD_VIA_T_RESET:
            return initialState;

        case actionTypes.HIRE_CARD_VIA_T_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        case actionTypes.HIRE_CARD_VIA_T_CHECK_RADIO_BUTTON: {
            return state.merge({
                checkButtonRadio: action.payload.check,
                cardsDocumentsPrintedAndSigned: action.payload.check
            })
        };
        case actionTypes.HIRE_CARD_VIA_T_CHECK_RADIO_BUTTON_POLICY: {
            return state.merge({
                checkButtonRadioPolicy: action.payload.check,
                agreeIneCheckbox: action.payload.check,
                cardsDocumentsPrintedAndSignedPolicy: action.payload.check,
                isFetching: false
            })
        };
        case actionTypes.HIRE_CARD_VIA_T_PRINT_DOCUMENT:
            return state.merge({
                printDocument: true
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_PRINT_DOCUMENT:
            return state.merge({
                printDocument: action.payload.value,
                cardsDocumentsPrintedAndSigned: undefined
            });
        case actionTypes.HIRE_CARD_VIA_T_PRINT_DOCUMENT_POLICY:
            return state.merge({
                printDocumentPolicy: true,
                agreeIneCheckbox: true

            });
        case actionTypes.HIRE_CARD_VIA_T_SET_AGREEMENT_CHECKBOX_CC:
            return state.merge({
                agreeIneCheckbox: !state.get('agreeIneCheckbox')
            });
        case actionTypes.HIRE_CARD_VIA_T_AGREEINECHECKBOX:
            return state.merge({
                agreeIneCheckbox: true
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_RESET_INIT_SEND_EMAIL:
            return state.merge({
                hasBeenSentEmail: false,
                mailSent: false
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_RESET_AGREEMENT_CHECKBOX_CC:
            return state.merge({
                agreeIneCheckbox: false
            });
        default:
            return state;
    }
}

module.exports = hireCardViaTReducer;
