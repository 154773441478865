// @ vendor
const Immutable = require('immutable');
const moment = require('moment');

// @ constants
const actionTypes = require('constants/actionTypes');

// @ utilities
const DateHelper = require('utilities/dateHelper');
const { formatText } = require('core/i18n').i18n;


function setInitialState() {
    const firstDay = moment().startOf('day');
    const limitDate = DateHelper.getNextBussinessDay(false, firstDay);
    const initialDate = limitDate

    return Immutable.Map().merge({
        valid: false,
        amount: 0,
        minimumPurchaseAmount: 10,
        amountErrorState: false,
        amountErrorMsg: '',
        selectedDate: initialDate,
        minDate: limitDate,
        isValidDate: true,
        periodicityLabel: 'Mensual',
        periodicityValue: 'Monthly',
        periodicityMinimum: 0,
        accountNumber: '',
        interveners: Immutable.List(),
        isSubscription: false,
        amountIsValid: true,
        amountDirty: false,
        hasPreviousContributions: false,
        periodicityOptions: [
            {
                label: 'brokerContributionFundStep1-weeklyPeriodicity',
                frecuenciaCuotaCada: 1,
                frecuenciaCuotaCadaUnidadDeTiempo: 2,
                value: 'Weekly'
            },
          {
              label: 'brokerContributionFundStep1-monthlyPeriodicity',
              frecuenciaCuotaCada: 1,
              frecuenciaCuotaCadaUnidadDeTiempo: 2,
              value: 'Monthly'
          },
          {
              label: 'brokerContributionFundStep1-quarterlyPeriodicity',
              frecuenciaCuotaCada: 3,
              frecuenciaCuotaCadaUnidadDeTiempo: 2,
              value: 'Quarterly'
          },
          {
              label: 'brokerContributionFundStep1-semestralPeriodicity',
              frecuenciaCuotaCada: 6,
              frecuenciaCuotaCadaUnidadDeTiempo: 2,
              value: 'Semiannual'
          },
          {
              label: 'brokerContributionFundStep1-annualPeriodicity',
              frecuenciaCuotaCada: 1,
              frecuenciaCuotaCadaUnidadDeTiempo: 3,
              value: 'Annual'
          }
      ],
    });
}

function isReady(state, value) {
    const amount = value !== undefined ? parseFloat(value) : parseFloat(state.get('amount'));
    const validAmountMinimum = amount >= state.getIn(['contributionFunds', 'customMinimumPurchaseAmount', 'additionalSubscriptionAmount']);
    const isValidDate = state.get('isValidDate')
    const ready = validAmountMinimum && isValidDate

    return state.merge({
        valid: ready,
        amount: amount,
        amountDirty: true,
        amountErrorState: validAmountMinimum ? false : true,
        amountErrorMsg: validAmountMinimum ? null : formatText('brokerContributionFundStep1-minimumAmountError')
    });
}

function contributionFundsStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_ACCOUNT_NUMBER:
            return state.mergeDeep({
                accountNumber: action.payload.accountId,
                accountAmount: { amount: action.payload.accountAmount },
                accountIsValid: !action.payload.linkedToRobo,
            });
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_AMOUNT:
            return isReady(state, action.payload.amount);
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_CHANGE_VALIDATE_STEP:
            return isReady(state);
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_DATE_VALUE:
            return state.mergeDeep({selectedDate: action.payload.date, isValidDate: true});
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_DATEPICKER_ERROR:
            return state.mergeDeep({isValidDate: action.payload.isValidDate});
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_PERIODICITY:
            return state.mergeDeep({periodicityValue: action.payload.value, periodicityLabel: action.payload.label});
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_PREVIOUS_DATA:
            return state.mergeDeep({
                    periodicityValue: action.payload.periodicityValue,
                    periodicityLabel: action.payload.periodicityLabel,
                    selectedDate: action.payload.nextInstallmentDate,
                    subscriptionDateId: action.payload.subscriptionDateId,
                    amount: action.payload.amount,
                    hasPreviousContributions: action.payload.hasPreviousContributions
                });
    }
    return state;
}

module.exports = contributionFundsStep1;
