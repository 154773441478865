// @vendors
const Immutable = require('immutable');
 
// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
    FOURTH_STEP
} = require('constants/index');

// @reducers
const hireCardOpenDebitStep1 = require('./hireCardOpenDebit/hireCardOpenDebitStep1');
const hireCardOpenDebitStep2 = require('./hireCardOpenDebit/hireCardOpenDebitStep2');
const hireCardOpenDebitStep3 = require('./hireCardOpenDebit/hireCardOpenDebitStep3');
const hireCardOpenDebitStep4 = require('./hireCardOpenDebit/hireCardOpenDebitStep4');


const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    error: false,
    errorMsg: '',
    isFetching: false,
    isFetchingBlocked: true,
    steps: [
        hireCardOpenDebitStep1(),
        hireCardOpenDebitStep2(),
        hireCardOpenDebitStep3(),
        hireCardOpenDebitStep4()
    ],
    submitSuccessful: false,
    visibleStep: 1,
    willCancel: false,
    loadingFlag: false
});

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return hireCardOpenDebitStep1(step, action);
            case SECOND_STEP:
                return hireCardOpenDebitStep2(step, action);
            case THIRD_STEP:
                return hireCardOpenDebitStep3(step, action);
            case FOURTH_STEP:
                return hireCardOpenDebitStep4(step, action);
        }
    });
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps){
        nextStep = currentStep;
    }

    return nextStep;
}

function hireCardOpenDebitReducer(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_CARD_OPEN_DEBIT_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            switch (currentStep) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 4:
                    partialSteps = updateStep(FOURTH_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
            case actionTypes.HIRE_CARD_LOADING:
                return state.merge({
                    loadingFlag: action.payload.loadingFlag
                });    
        case actionTypes.HIRE_CARD_OPEN_DEBIT_SET_ACCOUNT:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_OPEN_DEBIT_SET_AGREEMENT_CHECKBOX:
            const stepToUpdate = __CONTACT_CENTER__ ? FIRST_STEP : FOURTH_STEP;
            return state.merge({
                steps: updateStep(stepToUpdate, state.get('steps'), action)
            });

        case actionTypes.HIRE_CARD_OPEN_DEBIT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });

        case actionTypes.HIRE_CARD_OPEN_DEBIT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });

        case actionTypes.HIRE_CARD_OPEN_DEBIT_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });

        case actionTypes.HIRE_CARD_OPEN_DEBIT_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true
            });
        case actionTypes.HIRE_CARD_OPEN_DEBIT_BLOCKED_SUCCESS:
            return state.merge({
                isFetchingBlocked: false,
            });
        case actionTypes.HIRE_CARD_OPEN_DEBIT_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                isFetching: false,
                isFetchingBlocked: false
            });

        case actionTypes.HIRE_CARD_OPEN_DEBIT_RESET:
            return initialState;

        case actionTypes.HIRE_CARD_OPEN_DEBIT_SET_ALL_ACCOUNTS_TO_INACTIVE:
            return state.merge({
                allAccountsInactive: true
            });

        default:
            return state;
    }
}

module.exports = hireCardOpenDebitReducer;
